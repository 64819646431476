@import '../../../constants/scss/everything';

.yo-sharing-input__image {
  border: 1px solid var(--border-primary);
  margin-bottom: 2em;
  text-align: center;

  &--id {
    @include default-s;
    margin-bottom: .5em;
  }

  &--preview {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    max-width: 40%;
  }
}

.yo-sharing-input__description {
  @include default-s;
  color: var(--text-secondary);
}

.yo-sharing-input__required {
  color: var(--text-accent--error);
  margin-left: .25em;
}
