@import '../../../constants/scss/everything';

.yo-app-header {
  padding: .75rem 1.25rem;
  text-align: right;

  &__test {
    color: var(--text-white);
    @include default-2xl-bold;
    margin: 0 auto;
  }
}

.yo-app-header__links {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.yo-app-header__link {
  @include default-s-bold;
  color: var(--text-accent--light);
  margin: 0 1em;
  opacity: .67;
  transition: opacity 80ms ease-in-out;

  &:active,
  &:hover,
  &--active {
    opacity: 1;
  }
}

.yo-app-header__link--logout {
  @include default-xs;
  border: 1px solid var(--border-primary--xdark);
  margin-left: 2rem;
  margin-right: 0;
  opacity: 1;
  padding: .4rem;
  text-align: center;
  width: 5rem;

  &:hover {
    background-color: var(--background-accent--dark);
  }
}

@include for-tablet-up {
  .yo-app-header__link-button {
    margin: 0 0 0 2rem;
  }
}
