@import "../../../constants/scss/everything";

@mixin blurred {
    filter: blur(2px);
    pointer-events: none;
    opacity: .5;
}

.yo-widget {
    margin-bottom: 2.25rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 60rem;
    position: relative;

    &--editing {
        margin-left: initial;
        margin-right: initial;
        max-width: initial;
    }

    &--blurred {
        pointer-events: none;
        opacity: .75;
    }

    &--webview {
      margin: 1em;
    }
}

.yo-widget__exam-ended {
    margin-bottom: $yds-spacing-8;
    display: block;
}

.yo-widget__actions-buttons {
    justify-content: flex-end;
}

.yo-widget__login-warning {
    margin-bottom: $yds-spacing-16;
}
