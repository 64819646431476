@import '../../../constants/scss/everything';

.yo-google-sign-in {
  margin: $yds-spacing-64;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.yo-google-sign-in__header {
  margin-bottom: $yds-spacing-48;
}
