@import '../../../constants/scss/everything';

.yo-multiple-choice-question__options {
   grid-gap: $yds-spacing-8;
   display: grid;

  .yo-image__captions > .yo-image__copyright {
    color: var(--text-primary);
    padding: 8px 16px;
    margin: 0;
  }
}

@include for-tablet-up {
  .yo-multiple-choice-question__options--two-columns {
    grid-template-columns: 1fr 1fr;
  }
}

.yo-multiple-choice-question__show-poll-results {
  @include default-s;
  color: var(--text-secondary);

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.choice-button-stats-container {
  margin: 0 16px;
}
