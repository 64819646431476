@import '../../../constants/scss/everything';

.yo-login-warning {
  margin-top: $yds-spacing-16;
}

.yo-login-warning__link {
  @include default-s-bold;
  padding-left: $yds-spacing-2;
  text-decoration: underline;
  color: var(--yds-color-text-dark);
}
