@import '../../../constants/scss/everything.scss';

.yo-answer-validator {
  display: inline-block;

  svg {
    color: var(--yds-color-feedback-success);
    display: inline;
    vertical-align: middle;
  }

  &--incorrect {
    svg {
      color: var(--yds-color-feedback-error);
    }
  }
}
