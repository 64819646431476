@import '../../../constants/scss/everything';

.yo-autocomplete {
  position: relative;
}

.yo-autocomplete-menu {
  @include default-xs;
  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  border-radius: 2px;
  box-shadow: 0 0 1rem 0 var(--shadow);
  cursor: pointer;
  left: 0;
  max-height: 33vh;
  overflow: auto;
  position: absolute;
  top: 2.25rem;
  width: 100%;
  z-index: 1;

  &--hidden {
    visibility: hidden;
  }
}

.yo-autocomplete-item {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  padding: .5rem 1rem;
  color: var(--text-primary);

  &--highlighted {
    background-color: var(--background-accent--light);
    color: var(--text-accent--light);
    padding: .5rem 1rem;
  }
}

.yo-autocomplete-item__description {
  overflow: hidden;
  padding: .5rem 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.yo-autocomplete-item__hits {
  background-color: var(--background-accent);
  border: 1px solid var(--border-secondary);
  border-radius: 3px;
  color: var(--text-accent--light);
  margin-right: .5rem;
  padding: .25rem;

  &--highlighted {
    background-color: transparent;
    border: 1px solid var(--border-secondary);
  }
}

.yo-autocomplete-item__row {
  align-items: center;
  display: flex;
  width: 100%;
}
