@import '../../../constants/scss/everything';

.yo-wizard {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yo-wizard__heading {
  margin: 2rem 0;
  text-align: center;
}

.yo-wizard__create-buttons {
  margin: 2rem 0;
  text-align: center;
}

.yo-wizard__exam-type {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yo-settings__exam-type-selection {
  border: 1px dashed var(--border-primary);
  color: var(--text-secondary);
  cursor: pointer;
  display: flex;
  margin: .5rem;
  padding: 1em;
  width: 50%;

  &:active,
  &:hover {
    // Style the :active / :hover states of all but the currently selected item
    &:not(.yo-settings__exam-type-selection--current) {
      color: var(--text-primary);
    }
  }

  &--current {
    color: var(--text-accent--dark);
    cursor: default;
  }
}

.yo-settings__exam-type-selection-input {
  margin-right: 2rem;
}

.yo-settings__exam-type-selection-text-wrapper {
  display: block;
}

.yo-settings__exam-type-selection-name {
  font-weight: bold;
  display: block;
}

.yo-settings__exam-type-selection-description {
  @include default-s;
  &-link {
    color: inherit;
  }
}
