@import '../../../constants/scss/everything';

.yo-toolbar-plugin__divider {
  width: 1px;
  background-color: var(--border-primary);
  margin: 0 $yds-spacing-4;
}

.yo-rich-text-editor-style {
  &__bold {
    font-weight: bold;
  }

  &__italic {
    font-style: italic;
  }

  &__paragraph {
    display: block !important;
  }
}

.yo-rich-text-editor__toolbar {
  display: flex;
  flex-wrap: wrap;
  border-bottom: $yds-border-sm solid var(--border-primary);
  background: var(--background-primary);
  padding: $yds-spacing-4;
  border-top-left-radius: $yds-border-radius-large;
  border-top-right-radius: $yds-border-radius-large;
  vertical-align: middle;

  & svg {
    color: var(--text-secondary);
    fill: var(--text-secondary);
  }

  & svg.active {
    color: var(--text-primary);
  }

  & .active svg {
    fill: var(--text-primary);
  }

  & svg.disabled {
    color: var(--text-secondary--light);
    fill: var(--text-secondary--light);
  }
}

.yo-rich-text-editor__textarea {
  min-height: 12em;
  resize: none;
  caret-color: var(--text-primary);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: $yds-spacing-16 $yds-spacing-12;
  cursor: text;
}

.yo-rich-text-editor__placeholder {
  color: var(--text-secondary);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: $yds-spacing-16;
  left: $yds-spacing-12;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.yo-rich-text-editor__inner {
  background: var(--background-primary);
  position: relative;
}

.yo-rich-text-editor {
  @include default-s;
  border-radius: $yds-border-radius-medium;
  position: relative;
  text-align: left;
  border-top-left-radius: $yds-border-radius-large;
  border-top-right-radius: $yds-border-radius-large;
  border: $yds-border-sm solid var(--border-primary);
}

.yo-rich-text-editor__toolbar-button {
  margin: 0 !important
}
