@import '../../../constants/scss/everything';

.yo-choice-input {
  display: flex;
  margin-bottom: .5rem;
}

.yo-choice-input__text {
  color: var(--text-primary);
  background-color: var(--background-primary);
  border: 1px solid var(--border-primary);
  flex-grow: 1;
  margin-right: .5rem;
  padding: .25rem;
}
