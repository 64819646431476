@import '../../../constants/scss/everything';

.yo-sidebar-header {
  align-items: center;
  background-color: var(--background-accent--secondary);
  border-bottom: 1px solid var(--border-primary);
  color: var(--text-accent--dark);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: -1em -1em 0;
  padding: .25em 1em;
}

.yo-sidebar-header__buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: .5em;
  margin-top: .5em;
}

.yo-sidebar-header__resize-button {
  display: none;
}

@include for-desktop-up {
  .yo-sidebar-header__buttons {
    justify-content: space-between;
    width: 5em;
  }

  .yo-sidebar-header__resize-button {
    display: initial;
    transition: transform 666ms cubic-bezier(.9, -.33, .1, 1.33);

    &--expanded {
      transform: rotate(180deg);
    }
  }
}
