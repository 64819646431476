@import '../../../constants/scss/everything';

.yo-reference__source {
  color: var(--text-secondary);
  font-style: italic;
  margin-left: .5em;

  &::before {
    content: '- ';
  }
}
