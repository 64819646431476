@import '../../../constants/scss/everything';

.yo-node-menu__item {
  border-bottom: 1px solid var(--border-primary);
  border-top: 1px solid var(--border-secondary);
  cursor: pointer;
  outline: 0;
  padding: 0 1em;

  &:active,
  &:hover {
    background: var(--background-primary--dark);
  }

  &:first-of-type {
    border-top: 0;
    padding-top: .25em;
  }

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: .25em;
  }
}

.yo-node-menu__item-description {
  @include default-s;
  color: var(--text-secondary--dark);
  font-weight: 300;
  margin-bottom: .75rem;
  margin-top: .25rem;
  pointer-events: none;
}

.yo-node-menu__item-name {
  font-weight: 500;
  margin-bottom: .25rem;
  margin-top: .75rem;
  pointer-events: none;
}
