@import '../../../constants/scss/everything';

$column-width: 165px;

.yo-characters {
  background: var(--background-primary);
  margin: 0 auto;
  overflow-x: auto;

  &__add {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  &__container {
    display: flex;
  }
}

.yo-character-question {
  padding-bottom: 1em;
}

.yo-characters-title__container {
  display: flex;
  padding: 0 $yds-spacing-8;
  max-width: $column-width;
  min-width: $column-width;

  &-first {
    margin-left: $column-width;
  }
}

.yo-characters-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.yo-character-important {
  @include for-desktop-up {
    margin-top: 2.5em;
  }
  margin-top: 3em;
  padding-bottom: .5em;

  &__checkbox {
    @include for-desktop-up {
      margin: .5em 0;
    }
    margin: 1em 0;
    width: 6.5em;
  }
}

.yo-character-choice {
  @include for-desktop-up {
    margin-bottom: 1.2em;
    margin-top: .3em;
  }
  margin-bottom: 1.5em;
  margin-top: .1em;
  padding-bottom: .1em;
}

.yo-character-question__wrapper {
  display: flex;
}

.yo-characters-labels {
  width: $column-width;

  &__item {
    @include for-desktop-up {
      padding: .25em 0;
    }
    flex-basis: 100%;
    margin-right: 1em;
    min-height: 2em;
    overflow: hidden;
    padding: .5em 0;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__question {
    font-weight: bold;
  }
}

.yo-character-edit {
  margin: 0 auto;
  max-width: 40rem;

  &__button-container {
    display: flex;
    justify-content: center;
  }

  &__button {
    padding: .5rem 2rem;
  }

  &__container {
    display: flex;
  }

  &__preview {
    margin: 0 2em;
  }

  &__editor {
    background: var(--background-secondary);
    padding: 1em;
  }
}

.yo-character-preview {
  &__wrapper {
    border: 1px solid var(--border-primary);
    padding: 1em;
  }
}

.yo-character-choices {
  padding-left: $yds-spacing-8;
  width: $column-width;
}

.yo-characters-header {
  display: flex;
}

.yo-characters__sharing {
  border-top: 1px solid var(--border-primary);
  margin-top: 1em;
  padding-top: 1em;
}
