@import '../constants/scss/lightmode';
@import '../constants/scss/darkmode';
@import '../constants/scss/everything';

.yo-tehtava, .yo-page {
  & p {
    display: inline;
    margin-block-end: 0;
    margin-block-start: 0;
    color: var(--text-primary);
  }
  background-color: var(--background-primary);
  color: var(--text-primary);
}

.yo-fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}
