@import '../../../constants/scss/everything';

.yo-node-review__tags {
  align-items: baseline;
  display: flex;
  margin: 0 0 $yds-spacing-32;

  & .yo-tags {
    margin: 0;
  }

  & .yo-tags__tag {
    margin-right: $yds-spacing-8;

    &::after {
      content: ',';
    }

    &:last-of-type {
      &::after {
        content: initial;
      }
    }
  }

  & .yo-tags__tag-title {
    padding: 0;
  }
}

.yo-node-review__tunnus-disclaimer {
  @include default-s;
  font-style: italic;
  text-align: center;
}

.yo-node-review__tag-title {
  @include default-s;
  color: var(--text-primary--light);
  margin-right: $yds-spacing-8;

  &::after {
    content: ':';
  }
}

.yo-node-review__tag-title-clickable {
  &:hover {
    color: var(--text-accent);
    cursor: pointer;
  }
}

.yo-node-review-enter {
  height: .001rem;
  opacity: .01;
}

.yo-node-review-enter-active {
  height: ($yds-spacing-16 + $yds-spacing-4);
  opacity: 1;
  transition: height 120ms ease-in-out, opacity 120ms ease-in-out 120ms;
}

.yo-node-review-exit {
  height: ($yds-spacing-16 + $yds-spacing-4);
  opacity: 1;
}

.yo-node-review-exit-active {
  height: .001rem;
  opacity: .01;
  transition: height 120ms ease-in-out 120ms, opacity 120ms ease-in-out;
}

.yo-node-review {
  margin-top: $yds-spacing-16;

  .yo-node-review__feedback-link {
    @include default-s;
    color: var(--text-secondary--light);
    margin-left: auto;
    padding-left: $yds-spacing-16;
    text-decoration: none;

    &:hover {
      color: var(--text-secondary);
      text-decoration: underline;
    }
  }
}

@include for-tablet-up {
  .yo-node-review__tunnus-disclaimer {
    text-align: right;
  }
}

.yo-node-review-notification-content {
  display: grid;
  gap: $yds-spacing-16;
}

.yo-node-review-notification-feedback {
  display: grid;
  gap: $yds-spacing-8;
}
