@import '../../../constants/scss/everything';

.yo-scored-vote-question__selected {
  display: flex;

  &--column {
    flex: 1 0;
    flex-direction: column;
  }
}

.yo-scored-vote-question__options {
  & > *:first-child {
    margin-top: $yds-spacing-8;
  }

  & > *:not(:last-child) {
    margin-bottom: $yds-spacing-8;
  }
}

.yo-scored-vote-question__info {
  @include default-l;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.yo-scored-vote-question__info-button {
  margin: $yds-spacing-8;
}

.yo-scored-vote-question__stats {
  display: flex;
  flex-direction: column;
}

.yo-scored-vote-question__option {
  &--wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $yds-spacing-8;
  }

  &--index {
    @include default-m-bold;
    margin-right: $yds-spacing-12;
  }
}

.yo-scored-vote-results {
  @include default-s;
  color: var(--text-secondary);

  &__option-result {
    margin-top: $yds-spacing-4;
    margin-bottom: $yds-spacing-4;

    &-title {
      display: block;
    }

    &-values {
      @include default-s;
      margin-left: $yds-spacing-8;
      color: var(--text-secondary);
    }
  }
}
