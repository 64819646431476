@import '../../../constants/scss/everything';

.yo-input {
  margin-bottom: 1em;

  &--radio {
    padding: .3em;
  }

  &--separator {
    border-bottom: 1px solid var(--border-primary);
    margin-bottom: 2em;
    padding-bottom: 2em;
  }

  &--inline {
    display: inline;
    margin-right: 1em;
  }

  &--disabled {
    color: var(--text-secondary--light);
    opacity: .5;
  }
}

.yo-input__answer {
  p {
    font-weight: 700;
  }
}

.yo-input--error {
  border-bottom: 1px solid var(--text-accent--error);
}

.yo-input__required {
  color: var(--text-accent--error);
  margin-left: .25em;
}

.yo-input__counter {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1em;
}

.yo-input__description {
  @include default-s;
}

.yo-input__datetimepicker-description {
  @include default-s;
  margin: .5em 0 1em;
}

.yo-input__description-error {
  @include default-s;
  color: var(--text-accent--error);
  margin-bottom: 1em;
}

.yo-input__checked {
  &--correct,
  &--incorrect {
    border-radius: 2px;
    transition: background-color .3s;
  }

  &--correct {
    background-color: var(--background-input--correct);
  }

  &--incorrect {
    background-color: var(--background-input--incorrect);
  }
}

.yo-input__input {
  &--checkbox,
  &--radio {
    margin: 0 1em 0 0;
    flex-shrink: 0;
  }

  &--text,
  &--number {
    @include default-s;
    border: 1px solid var(--border-primary--dark);
    border-radius: 2px;
    box-shadow: inset 1px 1px .25rem var(--shadow);
    display: block;
    margin: .25rem 0;
    padding: .25rem;
    width: 100%;
    background-color: var(--background-primary);
    color: var(--text-primary);

    &:disabled {
      color: var(--text-secondary--light);
    }
  }

  &--narrow {
    width: 6em;
  }

  &--inline {
    display: inline;
    margin-left: .5em;
  }

  &--wrapper {
    align-items: baseline;
    display: flex;
  }

  &--buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &--clear-button {
    margin-left: auto;
  }

  &--date-now-button, &--clear-button {
    color: var(--text-accent);
    font-weight: bold;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.yo-input__optional {
  @include default-xs;
  color: var(--text-secondary);

  &::after {
    content: ')';
  }

  &::before {
    content: '(';
    margin-left: .8em;
  }
}

.yo-input__label {
  &--checkbox {
    align-items: baseline;
    display: flex;
  }
}

.yo-input__label-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: .5em;
}

.yo-input__input--range {
  height: 1.2rem;
  margin: .8em .5em;
  vertical-align: middle;
  width: 75%;
}

.yo-input__datetimepicker {
  input {
    @include default-s;
    border: 1px solid var(--border-primary--dark);
    background-color: var(--background-primary);
    color: var(--text-primary);
    padding: .2em;
    width: 100%;
  }
}

/** Styles for 3rd party component */
.yo-input__datetimepicker .rdtPicker {
  background-color: var(--background-primary);
  table tr {
    .rdtDay, .rdtTimeToggle, .rdtPrev, .rdtSwitch, .rdtNext, .rdtBtn {
      &:hover {
        background-color: var(--background-accent--secondary);
      }
    }
  }
}
