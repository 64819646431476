@import '../../../constants/scss/everything';

.yo-exam-info__carousel {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yo-exam-info {
  margin-bottom: $yds-spacing-16;

  h2.yo-primary-header {
    margin: 0;
    padding: $yds-spacing-4 0;
  }
}
