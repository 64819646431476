.yo-primary-header {
  font-size: 24px;
  text-decoration: none;
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0;
  line-height: 140%;
}
.yo-app-header {
  padding: 0.75rem 1.25rem;
  text-align: right;
}
.yo-app-header__test {
  color: rgb(255, 255, 255);
  color: var(--text-white);
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
  margin: 0 auto;
}

.yo-app-header__links {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.yo-app-header__link {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  color: rgba(255, 255, 255, .95);
  color: var(--text-accent--light);
  margin: 0 1em;
  opacity: 0.67;
  transition: opacity 80ms ease-in-out;
}
.yo-app-header__link:active, .yo-app-header__link:hover, .yo-app-header__link--active {
  opacity: 1;
}

.yo-app-header__link--logout {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  border: 1px solid rgba(32, 32, 32, .2);
  border: 1px solid var(--border-primary--xdark);
  margin-left: 2rem;
  margin-right: 0;
  opacity: 1;
  padding: 0.4rem;
  text-align: center;
  width: 5rem;
}
.yo-app-header__link--logout:hover {
  background-color: rgb(0, 95, 105);
  background-color: var(--background-accent--dark);
}

@media only screen and (min-width: 480px) {
  .yo-app-header__link-button {
    margin: 0 0 0 2rem;
  }
}
.yo-icon {
  display: inline-flex;
  padding: 0.25rem;
}
.yo-icon svg {
  fill: rgb(0, 120, 130);
  fill: var(--fill-primary);
}
.yo-icon--small {
  padding: 0.25rem;
}
.yo-loader {
  align-items: center;
  display: flex;
}

.yo-loader__animation {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-user-select: none;
          user-select: none;
  z-index: 9999;
}
.yo-loader__animation--spinner {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: spinningProgressBar;
          animation-name: spinningProgressBar;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.yo-loader__animation--horizontal {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: horizontalProgressBar;
          animation-name: horizontalProgressBar;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent--light);
  height: 0.125rem;
  left: 0;
  position: relative;
  top: 0;
  width: 0%;
}

.yo-loader__dots {
  display: flex;
  margin: 6rem auto;
}

.yo-loader__dot {
  -webkit-animation: dots 2s infinite ease-in-out both;
          animation: dots 2s infinite ease-in-out both;
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent--light);
  border-radius: 100%;
  height: 1rem;
  margin: 0 0.25rem;
  width: 1rem;
}
.yo-loader__dot--dot2 {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}
.yo-loader__dot--dot3 {
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
}

.yo-loader__scaleout {
  -webkit-animation: scaleOut 2s infinite ease-in-out;
          animation: scaleOut 2s infinite ease-in-out;
  border: 0.25em solid rgb(0, 120, 130);
  border: 0.25em solid var(--background-accent--light);
  border-radius: 100%;
  height: 3rem;
  margin: 6rem;
  width: 3rem;
}

@-webkit-keyframes dots {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes dots {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes horizontalProgressBar {
  0% {
    width: 0%;
  }
  25% {
    width: 22%;
  }
  50% {
    width: 55%;
  }
  75% {
    width: 83%;
  }
  100% {
    width: 100%;
  }
}
@keyframes horizontalProgressBar {
  0% {
    width: 0%;
  }
  25% {
    width: 22%;
  }
  50% {
    width: 55%;
  }
  75% {
    width: 83%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes spinningProgressBar {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes spinningProgressBar {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes scaleOut {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}
@keyframes scaleOut {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}
.yo-google-sign-in {
  margin: 64px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.yo-google-sign-in__header {
  margin-bottom: 48px;
}
.yo-toast {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  align-items: center;
  background-color: rgb(0, 95, 105);
  background-color: var(--background-accent--dark);
  border: 1px solid rgba(32, 32, 32, .2);
  border: 1px solid var(--border-primary--xdark);
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: 0;
  color: rgba(255, 255, 255, .95);
  color: var(--text-accent--light);
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  opacity: 1;
  padding: 0.75em 1.5em 0.5em;
  pointer-events: auto;
  position: fixed;
  top: 0;
  transition: opacity 250ms, top 250ms cubic-bezier(0.9, -0.33, 0.1, 1.33);
}

.yo-toast__icon {
  margin-left: 1em;
}
.yo-toast__icon--progress {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.yo-toast__message {
  margin: 0 0.25rem;
  padding: 0.25rem 0;
}

.yo-toast-enter {
  opacity: 0.01;
  top: -100px;
}

.yo-toast-enter-active {
  opacity: 1;
  top: 0;
}

.yo-toast-exit {
  opacity: 1;
  top: 0;
}

.yo-toast-exit-active {
  opacity: 0.01;
  top: -100px;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
.yo-autocomplete {
  position: relative;
}

.yo-autocomplete-menu {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  background: rgb(253, 253, 253);
  background: var(--background-primary);
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
  border-radius: 2px;
  box-shadow: 0 0 1rem 0 rgba(128, 128, 128, .1);
  box-shadow: 0 0 1rem 0 var(--shadow);
  cursor: pointer;
  left: 0;
  max-height: 33vh;
  overflow: auto;
  position: absolute;
  top: 2.25rem;
  width: 100%;
  z-index: 1;
}
.yo-autocomplete-menu--hidden {
  visibility: hidden;
}

.yo-autocomplete-item {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  padding: 0.5rem 1rem;
  color: rgb(16, 16, 16);
  color: var(--text-primary);
}
.yo-autocomplete-item--highlighted {
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent--light);
  color: rgba(255, 255, 255, .95);
  color: var(--text-accent--light);
  padding: 0.5rem 1rem;
}

.yo-autocomplete-item__description {
  overflow: hidden;
  padding: 0.5rem 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.yo-autocomplete-item__hits {
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent);
  border: 1px solid rgb(255, 255, 255);
  border: 1px solid var(--border-secondary);
  border-radius: 3px;
  color: rgba(255, 255, 255, .95);
  color: var(--text-accent--light);
  margin-right: 0.5rem;
  padding: 0.25rem;
}
.yo-autocomplete-item__hits--highlighted {
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255);
  border: 1px solid var(--border-secondary);
}

.yo-autocomplete-item__row {
  align-items: center;
  display: flex;
  width: 100%;
}
.yo-areena-player {
  min-width: 15em;
  max-height: 100vh;
  max-width: 100%;
  width: 100%;
}

div.yo-areena-player {
  overflow: hidden;
}

.yo-simple-media-player {
  position: relative;
}
.yo-simple-media-player video {
  max-height: 100vh;
  max-width: 100%;
  width: 100%;
}
.yo-simple-media-player .yo-icon-button {
  background-color: rgba(0, 0, 0, .3);
  background-color: var(--shadow--dark);
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  padding: 0.5em;
  position: absolute;
  right: 0;
  margin-right: 0.25em;
  margin-top: 0.25em;
  z-index: 1;
}

.yo-simple-media-player__error {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.yo-simple-media-player__error-div {
  position: relative;
  left: -50%;
  display: flex;
  align-items: center;
  z-index: 1;
}
.yo-simple-media-player__error-div .yo-icon svg {
  fill: rgb(16, 16, 16);
  fill: var(--text-primary);
}

.yo-simple-media-player__error-div-span {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  margin-left: 0.25em;
}
.yo-gap-option {
  display: inline-block;
  height: auto;
  margin: 0 4px;
  white-space: nowrap;
  width: auto;
}

.yo-gap-option__review {
  display: inline;
}

.yo-gap-option__answer {
  display: inline;
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
  padding-left: 8px;
  white-space: normal;
}

.yo-gap-option__hint {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(96, 96, 96);
  color: var(--text-secondary--dark);
  padding-right: 8px;
  white-space: initial;
}

.yo-gap-option__input {
  display: inline-block;
  height: auto;
  width: auto;
}
.yo-gap-option__input fieldset {
  display: inline;
}
.yo-gap-option__input fieldset input {
  height: 24px;
  padding: 8px;
}
.yo-gap-option__input fieldset label {
  display: none;
}

.yo-gap-option__numbering {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(96, 96, 96);
  color: var(--text-secondary--dark);
}
.yo-gap-option__numbering::before {
  counter-increment: gap-counter;
  content: counter(gap-counter) ") ";
  padding-right: 4px;
}
.yo-gap-select {
  white-space: nowrap;
}

.yo-gap-select__answer {
  font-weight: bold;
  white-space: initial;
}
.yo-gap-select__answer::before {
  content: " ";
}

.yo-gap-select__hint {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  white-space: initial;
}

.yo-gap-select__numbering {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(96, 96, 96);
  color: var(--text-secondary--dark);
}
.yo-gap-select__numbering::before {
  content: counter(gap-counter) ") ";
  counter-increment: gap-counter;
}

.yo-gap-select__select {
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
  color: rgb(16, 16, 16);
  color: var(--text-primary);
  max-width: calc(100% - 2em);
}
.yo-gap-select__select--validated {
  max-width: calc(100% - 4em);
}

.yo-fragment__text {
  counter-reset: gap-counter;
}
.yo-answer-validator {
  display: inline-block;
}
.yo-answer-validator svg {
  color: var(--yds-color-feedback-success);
  display: inline;
  vertical-align: middle;
}
.yo-answer-validator--incorrect svg {
  color: var(--yds-color-feedback-error);
}
.yo-image {
  margin: 0;
  position: relative;
}

.yo-image__captions {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  text-align: left;
  word-break: break-word;
  margin-top: 16px;
  margin-bottom: 32px;
}
.yo-image__captions--center {
  text-align: center;
}

.yo-image__caption-link {
  position: absolute !important;
  top: 8px;
  right: 8px;
}

.yo-image__caption-toggle {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  cursor: pointer;
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  font-style: italic;
  margin-left: 0.5em;
  outline: 0;
  -webkit-user-select: none;
          user-select: none;
  word-break: break-word;
}
.yo-image__caption-toggle::after {
  content: ")";
}
.yo-image__caption-toggle::before {
  content: "(";
}
.yo-image__caption-toggle:active, .yo-image__caption-toggle:hover {
  color: rgb(96, 96, 96);
  color: var(--text-secondary--dark);
}

.yo-image__copyright {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  display: block;
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  margin-top: 0.5em;
}
.yo-image__copyright:hover {
  color: rgb(192, 192, 192);
  color: var(--text-secondary--light);
}

.yo-image__image {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  border-radius: 4px;
}
.yo-link {
  color: rgb(16, 16, 16);
  color: var(--text-primary);
  text-decoration: underline;
}
.yo-link:active, .yo-link:hover {
  color: rgb(0, 95, 105);
  color: var(--text-accent--dark);
}
.yo-reference__source {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  font-style: italic;
  margin-left: 0.5em;
}
.yo-reference__source::before {
  content: "- ";
}
.yo-math__wrapper {
  margin: 0 0.25em;
}
/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */      /* Firefox */       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */      /* Firefox */       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.yo-input {
  margin-bottom: 1em;
}
.yo-input--radio {
  padding: 0.3em;
}
.yo-input--separator {
  border-bottom: 1px solid rgba(128, 128, 128, .2);
  border-bottom: 1px solid var(--border-primary);
  margin-bottom: 2em;
  padding-bottom: 2em;
}
.yo-input--inline {
  display: inline;
  margin-right: 1em;
}
.yo-input--disabled {
  color: rgb(192, 192, 192);
  color: var(--text-secondary--light);
  opacity: 0.5;
}

.yo-input__answer p {
  font-weight: 700;
}

.yo-input--error {
  border-bottom: 1px solid rgb(204, 0, 17);
  border-bottom: 1px solid var(--text-accent--error);
}

.yo-input__required {
  color: rgb(204, 0, 17);
  color: var(--text-accent--error);
  margin-left: 0.25em;
}

.yo-input__counter {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1em;
}

.yo-input__description {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
}

.yo-input__datetimepicker-description {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  margin: 0.5em 0 1em;
}

.yo-input__description-error {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(204, 0, 17);
  color: var(--text-accent--error);
  margin-bottom: 1em;
}

.yo-input__checked--correct, .yo-input__checked--incorrect {
  border-radius: 2px;
  transition: background-color 0.3s;
}
.yo-input__checked--correct {
  background-color: rgba(201, 234, 116, .6);
  background-color: var(--background-input--correct);
}
.yo-input__checked--incorrect {
  background-color: rgba(255, 131, 131, .4);
  background-color: var(--background-input--incorrect);
}

.yo-input__input--checkbox, .yo-input__input--radio {
  margin: 0 1em 0 0;
  flex-shrink: 0;
}
.yo-input__input--text, .yo-input__input--number {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  border: 1px solid rgba(96, 96, 96, .2);
  border: 1px solid var(--border-primary--dark);
  border-radius: 2px;
  box-shadow: inset 1px 1px 0.25rem rgba(128, 128, 128, .1);
  box-shadow: inset 1px 1px 0.25rem var(--shadow);
  display: block;
  margin: 0.25rem 0;
  padding: 0.25rem;
  width: 100%;
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
  color: rgb(16, 16, 16);
  color: var(--text-primary);
}
.yo-input__input--text:disabled, .yo-input__input--number:disabled {
  color: rgb(192, 192, 192);
  color: var(--text-secondary--light);
}
.yo-input__input--narrow {
  width: 6em;
}
.yo-input__input--inline {
  display: inline;
  margin-left: 0.5em;
}
.yo-input__input--wrapper {
  align-items: baseline;
  display: flex;
}
.yo-input__input--buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.yo-input__input--clear-button {
  margin-left: auto;
}
.yo-input__input--date-now-button, .yo-input__input--clear-button {
  color: rgb(0, 120, 130);
  color: var(--text-accent);
  font-weight: bold;
}
.yo-input__input--date-now-button:hover, .yo-input__input--clear-button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.yo-input__optional {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
}
.yo-input__optional::after {
  content: ")";
}
.yo-input__optional::before {
  content: "(";
  margin-left: 0.8em;
}

.yo-input__label--checkbox {
  align-items: baseline;
  display: flex;
}

.yo-input__label-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 0.5em;
}

.yo-input__input--range {
  height: 1.2rem;
  margin: 0.8em 0.5em;
  vertical-align: middle;
  width: 75%;
}

.yo-input__datetimepicker input {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  border: 1px solid rgba(96, 96, 96, .2);
  border: 1px solid var(--border-primary--dark);
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
  color: rgb(16, 16, 16);
  color: var(--text-primary);
  padding: 0.2em;
  width: 100%;
}

/** Styles for 3rd party component */
.yo-input__datetimepicker .rdtPicker {
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
}
.yo-input__datetimepicker .rdtPicker table tr .rdtDay:hover, .yo-input__datetimepicker .rdtPicker table tr .rdtTimeToggle:hover, .yo-input__datetimepicker .rdtPicker table tr .rdtPrev:hover, .yo-input__datetimepicker .rdtPicker table tr .rdtSwitch:hover, .yo-input__datetimepicker .rdtPicker table tr .rdtNext:hover, .yo-input__datetimepicker .rdtPicker table tr .rdtBtn:hover {
  background-color: rgb(235, 240, 240);
  background-color: var(--background-accent--secondary);
}
.yo-toolbar-plugin__divider {
  width: 1px;
  background-color: rgba(128, 128, 128, .2);
  background-color: var(--border-primary);
  margin: 0 4px;
}

.yo-rich-text-editor-style__bold {
  font-weight: bold;
}
.yo-rich-text-editor-style__italic {
  font-style: italic;
}
.yo-rich-text-editor-style__paragraph {
  display: block !important;
}

.yo-rich-text-editor__toolbar {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(128, 128, 128, .2);
  border-bottom: 1px solid var(--border-primary);
  background: rgb(253, 253, 253);
  background: var(--background-primary);
  padding: 4px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  vertical-align: middle;
}
.yo-rich-text-editor__toolbar svg {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  fill: rgb(118, 118, 118);
  fill: var(--text-secondary);
}
.yo-rich-text-editor__toolbar svg.active {
  color: rgb(16, 16, 16);
  color: var(--text-primary);
}
.yo-rich-text-editor__toolbar .active svg {
  fill: rgb(16, 16, 16);
  fill: var(--text-primary);
}
.yo-rich-text-editor__toolbar svg.disabled {
  color: rgb(192, 192, 192);
  color: var(--text-secondary--light);
  fill: rgb(192, 192, 192);
  fill: var(--text-secondary--light);
}

.yo-rich-text-editor__textarea {
  min-height: 12em;
  resize: none;
  caret-color: rgb(16, 16, 16);
  caret-color: var(--text-primary);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 16px 12px;
  cursor: text;
}

.yo-rich-text-editor__placeholder {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 16px;
  left: 12px;
  -webkit-user-select: none;
          user-select: none;
  display: inline-block;
  pointer-events: none;
}

.yo-rich-text-editor__inner {
  background: rgb(253, 253, 253);
  background: var(--background-primary);
  position: relative;
}

.yo-rich-text-editor {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  border-radius: 4px;
  position: relative;
  text-align: left;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
}

.yo-rich-text-editor__toolbar-button {
  margin: 0 !important;
}
.yo-separator {
  display: block;
  height: 0.1em;
  border: 0;
  border-top: 1px solid rgba(128, 128, 128, .2);
  border-top: 1px solid var(--border-primary);
  margin: 1em 0;
  padding: 0;
}
.yo-select {
  margin-bottom: 0.75rem;
}

.yo-select__description {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
}

.yo-select__label {
  margin-right: 0.25rem;
}

.yo-select__select {
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
  color: rgb(16, 16, 16);
  color: var(--text-primary);
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
  border-radius: 2px;
  display: block;
  height: 2em;
  margin: 0.25rem 0;
  max-width: 100%;
  padding: 0 0.75rem;
}
.yo-select__select--inline {
  display: inline;
}
.yo-dialog {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  outline: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.yo-dialog button:not(:active) {
  outline-width: initial;
}

.yo-dialog__background {
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.yo-dialog__label {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.yo-dialog__input {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  border: 1px solid rgba(96, 96, 96, .2);
  border: 1px solid var(--border-primary--dark);
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
  color: rgb(16, 16, 16);
  color: var(--text-primary);
  border-radius: 2px;
  box-shadow: inset 1px 1px 0.25rem rgba(128, 128, 128, .1);
  box-shadow: inset 1px 1px 0.25rem var(--shadow);
  display: block;
  margin: 0.25rem 0;
  max-width: 100%;
  padding: 0.25rem;
  width: 100%;
}
.yo-dialog__input--wrapper {
  border: 0;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  padding: 0;
}
.yo-dialog__input--wrapper .yo-button {
  margin: 0.25rem 0 0.25rem 0.75rem;
}

.yo-dialog__checkbox {
  margin: 0.25rem 0;
  padding: 0.25rem;
  width: 100%;
}

.yo-dialog__input-column {
  display: flex;
  flex: 1 1 80%;
  flex-flow: column wrap;
  margin: 0.25rem 0;
}

.yo-dialog__item {
  margin-bottom: 0.75rem;
}

.yo-dialog__popup {
  position: absolute;
  background: rgb(253, 253, 253);
  background: var(--background-primary);
  border-radius: 3px;
  box-shadow: 0 0 3.25rem 0 rgba(0, 0, 0, .3);
  box-shadow: 0 0 3.25rem 0 var(--shadow--dark);
  width: 100%;
  z-index: 20;
}

.yo-dialog__popup-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem 1rem;
}

.yo-dialog__popup-content {
  padding: 1rem;
}

.yo-dialog__popup-title {
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent--light);
  border-radius: 2px 2px 0 0;
  color: rgba(255, 255, 255, .95);
  color: var(--text-accent--light);
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.yo-dialog__description {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  text-align: center;
}

@media only screen and (min-width: 480px) {
  .yo-dialog__select {
    margin-left: 1em;
  }
  .yo-dialog__input {
    flex: 1 1 80%;
  }
  .yo-dialog__label {
    align-items: center;
    flex-direction: row;
  }
  .yo-dialog__title {
    flex: 1 0 20%;
    margin-right: 1rem;
    text-align: right;
  }
  .yo-dialog__popup {
    min-width: 32rem;
    width: auto;
    max-width: 48rem;
  }
}
.yo-tags {
  margin: 1em 0;
}

.yo-tags__tag {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  align-items: center;
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent--light);
  border-radius: 0.25rem;
  color: rgba(255, 255, 255, .95);
  color: var(--text-accent--light);
  cursor: default;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  padding: 0.125rem 0.25rem;
}
.yo-tags__tag:active, .yo-tags__tag:hover {
  background: rgb(0, 120, 130);
  background: var(--background-accent);
}
.yo-tags__tag:first-of-type {
  margin-left: 0;
}
.yo-tags__tag:last-of-type {
  margin-right: 0;
}
.yo-tags__tag--read-only:active, .yo-tags__tag--read-only:hover {
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent--light);
  cursor: default;
}
.yo-tags__tag--carousel-clickable:hover {
  color: rgb(0, 95, 105);
  color: var(--text-accent--dark);
  cursor: pointer;
}
.yo-tags__tag--secondary {
  background: transparent;
  border-radius: 0;
  color: rgb(0, 120, 130);
  color: var(--text-accent);
  font-style: italic;
  margin: 0;
  padding: 0;
}
.yo-tags__tag--secondary:active, .yo-tags__tag--secondary:hover {
  background: transparent;
}

.yo-tags__tag-close {
  display: inline-block;
  margin-left: 0.5em;
  outline: 0;
}
.yo-tags__tag-close:active, .yo-tags__tag-close:hover {
  cursor: pointer;
}
.yo-tags__tag-close:active .yo-icon-svg-path, .yo-tags__tag-close:hover .yo-icon-svg-path {
  fill: rgb(255, 255, 255);
  fill: var(--fill-secondary);
}

.yo-tags__tag-link {
  color: rgba(255, 255, 255, .95);
  color: var(--text-accent--light);
  text-decoration: none;
}

.yo-tags__tag-title {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.yo-icon-buttons {
  cursor: default;
  display: inline-flex;
  margin-right: 0.75rem;
}
.yo-icon-buttons:last-of-type {
  margin-right: 0;
}
.yo-icon-button {
  cursor: pointer;
  margin: 0 0.25rem;
  border: 0;
  background-color: rgba(160, 160, 160, .1);
  background-color: var(--background-primary--dark);
}
.yo-icon-button:first-of-type {
  margin-left: 0;
}
.yo-icon-button:active .yo-icon-svg-path, .yo-icon-button:hover .yo-icon-svg-path {
  opacity: 0.75;
}

.yo-icon-button__icon {
  height: 1rem;
}

.yo-icon-svg-text {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
}
.yo-sidebar-header {
  align-items: center;
  background-color: rgb(235, 240, 240);
  background-color: var(--background-accent--secondary);
  border-bottom: 1px solid rgba(128, 128, 128, .2);
  border-bottom: 1px solid var(--border-primary);
  color: rgb(0, 95, 105);
  color: var(--text-accent--dark);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: -1em -1em 0;
  padding: 0.25em 1em;
}

.yo-sidebar-header__buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.yo-sidebar-header__resize-button {
  display: none;
}

@media only screen and (min-width: 1023px) {
  .yo-sidebar-header__buttons {
    justify-content: space-between;
    width: 5em;
  }
  .yo-sidebar-header__resize-button {
    display: initial;
    transition: -webkit-transform 666ms cubic-bezier(0.9, -0.33, 0.1, 1.33);
    transition: transform 666ms cubic-bezier(0.9, -0.33, 0.1, 1.33);
    transition: transform 666ms cubic-bezier(0.9, -0.33, 0.1, 1.33), -webkit-transform 666ms cubic-bezier(0.9, -0.33, 0.1, 1.33);
  }
  .yo-sidebar-header__resize-button--expanded {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}
.yo-tertiary-header {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}
.yo-tabs__labels {
  background-color: rgb(235, 240, 240);
  background-color: var(--background-accent--secondary);
  border-bottom: 1px solid rgba(128, 128, 128, .2);
  border-bottom: 1px solid var(--border-primary);
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em -1em 0;
  padding: 0.75em 1em;
}

.yo-tabs__links {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  display: flex;
  flex-flow: row wrap;
}

.yo-tabs__link {
  align-items: center;
  color: rgb(0, 120, 130);
  color: var(--text-accent);
  cursor: pointer;
  display: flex;
  opacity: 0.67;
  outline: 0;
  padding: 0.5em;
  text-decoration: none;
  transition: opacity 80ms ease-in-out;
}
.yo-tabs__link:last-child {
  margin-right: 0;
}
.yo-tabs__link:active, .yo-tabs__link:hover, .yo-tabs__link--active {
  color: rgb(0, 95, 105);
  color: var(--text-accent--dark);
  opacity: 1;
}
.yo-tabs__link--active {
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
  border-radius: 2px;
}

.yo-tabs__panel {
  margin-top: 1em;
}

.yo-tabs__title {
  text-transform: uppercase;
  width: 100%;
}

@media only screen and (min-width: 480px) {
  .yo-tabs__link {
    margin-right: 0.75em;
  }
}
.yo-accordion {
  margin: 0.5rem 0;
}

.yo-accordion__content {
  padding: 1em;
}

.yo-accordion__items {
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
  border: 1px solid rgba(96, 96, 96, .2);
  border: 1px solid var(--border-primary--dark);
  box-shadow: 0 1px 2px rgba(128, 128, 128, .1);
  box-shadow: 0 1px 2px var(--shadow);
  margin: 0.75rem 0;
}

.yo-accordion__title {
  margin-bottom: 0.25rem;
}
.yo-accordion-item--highlighted {
  background-color: rgba(255, 250, 238, .5);
  background-color: var(--background-accent--highlight);
}
.yo-accordion-item--invalid {
  border: 1px solid rgb(204, 0, 17);
  border: 1px solid var(--text-accent--error);
}
.yo-accordion-item__toggle svg {
  fill: rgb(192, 192, 192);
  fill: var(--text-secondary--light);
}

.yo-accordion-item__description {
  font-weight: 400;
  margin-left: 0.85em;
}

.yo-accordion-item__title {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(96, 96, 96);
  color: var(--text-secondary--dark);
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.yo-accordion-item__header {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.25rem;
}
.yo-accordion-item__header--expanded {
  border-bottom: 1px dashed rgba(96, 96, 96, .2);
  border-bottom: 1px dashed var(--border-primary--dark);
}
.yo-accordion-item__header--expanded .yo-accordion-item__title {
  font-weight: 400;
}
.yo-accordion-item__header:focus, .yo-accordion-item__header:active, .yo-accordion-item__header:hover {
  background-color: rgba(255, 250, 238, .5);
  background-color: var(--background-accent--highlight);
}

.yo-accordion-item__prefix {
  align-items: center;
  display: flex;
  margin-right: 0.75rem;
  max-width: 100%;
}

.yo-accordion-item__suffix {
  margin-left: 0.75rem;
}
.yo-choice-input {
  display: flex;
  margin-bottom: 0.5rem;
}

.yo-choice-input__text {
  color: rgb(16, 16, 16);
  color: var(--text-primary);
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
  flex-grow: 1;
  margin-right: 0.5rem;
  padding: 0.25rem;
}
.yo-editor-choices {
  margin: 1rem 0;
}

.yo-editor-choices__title {
  margin-bottom: 0.5rem;
}
.yo-editor-settings__timed-question--wrapper {
  margin-bottom: 1em;
  margin-top: 1em;
}
.yo-scored-vote-scoring__score-input-label {
  min-width: 4.5em;
  margin-right: 0.5em;
  margin-top: 0.45em;
}
.yo-scored-vote-scoring__score-input-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.yo-pagination {
  padding-left: 0;
}

.yo-pagination-page-link {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(16, 16, 16);
  color: var(--text-primary);
  margin: 1em 0.5em;
  outline: 0;
  padding: 0.5em;
}

.yo-pagination-page {
  display: inline-block;
  padding: 0.3em 0;
}
.yo-pagination-page--active > .yo-pagination-page-link {
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent);
  color: rgba(255, 255, 255, .95);
  color: var(--text-accent--light);
}

@media only screen and (min-width: 1023px) {
  .yo-pagination-page-link {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
}
.exam-search {
  margin-top: auto;
}

.yo-exam-search__input {
  margin: 0.5em 0 0;
}
.yo-exam-search__input .yo-input__label > input {
  padding: 0.4em;
}
.yo-exam-list {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
}

.yo-exam-list__author::after {
  content: ", ";
  display: inline;
}
.yo-exam-list__author:last-of-type::after {
  display: none;
}

.yo-exam-list__button {
  margin: 2em 0;
}

.yo-exam-list__filter label {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.yo-exam-list__filter select {
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
}

.yo-exam-list__filters {
  width: 100%;
}

.yo-exam-list__header {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
}

.yo-exam-list__table-cell {
  display: table;
  padding: 0.5em;
}
.yo-exam-list__table-cell::before {
  content: attr(data-column-title);
  display: table-cell;
  font-weight: 600;
  padding-right: 1em;
  width: 6em;
}
.yo-exam-list__table-cell--actions > div {
  margin-left: -0.25rem;
}
.yo-exam-list__table-cell a {
  color: inherit;
  text-decoration: none;
}
.yo-exam-list__table-cell a:active, .yo-exam-list__table-cell a:hover {
  color: rgb(0, 120, 130);
  color: var(--text-accent);
}

.yo-exam-list__table-header {
  display: none;
}

.yo-exam-list__table-row {
  border-bottom: 1px solid rgba(128, 128, 128, .1);
  border-bottom: 1px solid var(--border-primary--light);
  display: flex;
  flex-flow: column nowrap;
  padding: 2em 0;
}
.yo-exam-list__table-row:first-of-type {
  padding-top: 0;
}
.yo-exam-list__table-row:last-of-type {
  border-bottom: 0;
}

.yo-exam-list__limit-select {
  margin-top: 0.75em;
  text-align: center;
}

.yo-exam-list__title--wrapper {
  margin-left: 0.5em;
  text-align: left;
  width: 15em;
}
.yo-exam-list__title--wrapper .pull-right {
  float: right;
}

.yo-exam-list__publish-title {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  border: 1px solid rgba(128, 128, 128, .1);
  border: 1px solid var(--border-primary--light);
  border-radius: 2px;
  display: inline-block;
  padding: 0.6em;
}
.yo-exam-list__publish-title--active {
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent);
  color: rgba(255, 255, 255, .95);
  color: var(--text-accent--light);
}

.yo-exam-list__pagination--wrapper {
  margin: 1em 0;
  text-align: center;
}

.yo-exam-list__action--wrapper {
  display: flex;
}

.yo-exam-list__search {
  align-items: flex-end;
  display: flex;
}

@media only screen and (min-width: 1023px) {
  .yo-exam-list__filter {
    margin: 0.25em 1em;
  }
  .yo-exam-list__filter label {
    align-items: center;
    flex-direction: row;
  }
  .yo-exam-list__filter .yo-select__label {
    margin-right: 1rem;
  }
  .yo-exam-list__filters {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1em 0;
  }
  .yo-exam-list__table-header {
    border-bottom: 1px solid rgba(128, 128, 128, .2);
    border-bottom: 1px solid var(--border-primary);
    display: flex;
    font-family: "Yle Next", "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
  }
  .yo-exam-list__table-cell {
    display: initial;
    flex: 1 1;
    outline: 0;
    padding-right: 1em;
  }
  .yo-exam-list__table-cell::before {
    display: none;
  }
  .yo-exam-list__table-cell:last-of-type {
    padding-right: 0;
  }
  .yo-exam-list__table-cell--actions {
    flex-grow: 1;
  }
  .yo-exam-list__table-cell--author {
    flex-grow: 3;
  }
  .yo-exam-list__table-cell--name {
    flex-grow: 4;
  }
  .yo-exam-list__table-cell--updated-at {
    flex-grow: 3;
  }
  .yo-exam-list__table-cell > .yo-icon {
    padding: 0 0.25em;
  }
  .yo-exam-list__table-cell--sortable:hover {
    color: rgb(0, 120, 130);
    color: var(--text-accent);
    cursor: pointer;
  }
  .yo-exam-list__table-row {
    align-items: baseline;
    flex-flow: row nowrap;
    padding: 0.5em 0;
    width: 100%;
  }
  .yo-exam-list__limit-select {
    text-align: right;
  }
  .yo-exam-list__publish-title {
    -webkit-user-select: none;
            user-select: none;
  }
  .yo-exam-list__publish-title:hover {
    border-color: rgba(96, 96, 96, .2);
    border-color: var(--border-primary--dark);
    cursor: pointer;
  }
  .yo-exam-list__pagination--wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }
}
.yo-dropdown {
  background: rgb(248, 248, 248);
  background: var(--background-secondary);
  border: 1px solid rgba(96, 96, 96, .2);
  border: 1px solid var(--border-primary--dark);
  border-radius: 0.125em;
  box-shadow: 0 0 0.25rem 2px rgba(128, 128, 128, .1);
  box-shadow: 0 0 0.25rem 2px var(--shadow);
  display: flex;
  flex-flow: column;
  outline: 0;
  padding: 1em;
  position: fixed;
  right: 7rem;
  text-align: left;
  top: 6rem;
  transition: top 400ms cubic-bezier(0.9, -0.1, 0.1, 1.1);
}
.yo-dropdown--hidden {
  top: -14em;
}

.yo-dropdown__input {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0.25em 0;
  -webkit-user-select: none;
          user-select: none;
}

.yo-dropdown__input-field {
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
  color: rgb(96, 96, 96);
  color: var(--text-secondary--dark);
  margin-right: 0.5em;
  min-width: 25em;
  padding: 0.25rem;
  transition: background 200ms ease-in-out;
}
.yo-dropdown__input-field--copied {
  background-color: rgba(255, 250, 238, .5);
  background-color: var(--background-accent--highlight);
}

.yo-dropdown__input-title {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  flex: 1 0 auto;
  margin-right: 1em;
  text-align: right;
}

.yo-dropdown__input-notice {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  align-items: center;
  background: rgba(160, 160, 160, .1);
  background: var(--background-primary--dark);
  border-bottom: 1px solid rgba(128, 128, 128, .1);
  border-bottom: 1px solid var(--border-primary--light);
  cursor: default;
  display: flex;
  margin: -1rem -1rem 1rem;
  padding: 1rem 1rem 0.75rem;
  -webkit-user-select: none;
          user-select: none;
}

.yo-dropdown__input-notice-text {
  padding: 0.25rem;
}
.yo-header {
  background-color: rgb(0, 95, 105);
  background-color: var(--background-accent--dark);
  box-shadow: 0 2px 0.5rem rgba(0, 0, 0, .3);
  box-shadow: 0 2px 0.5rem var(--shadow--dark);
  width: 100%;
  z-index: 1;
}
.yo-sidebar {
  background-color: rgb(248, 248, 248);
  background-color: var(--background-secondary);
  border-bottom: 1px solid rgba(128, 128, 128, .2);
  border-bottom: 1px solid var(--border-primary);
  padding: 1em;
}

@media only screen and (min-width: 1023px) {
  .yo-sidebar {
    border-bottom: 0;
    border-right: 1px solid rgba(128, 128, 128, .2);
    border-right: 1px solid var(--border-primary);
    flex: 1 0 35%;
    overflow-y: auto;
    transition: flex-basis 150ms cubic-bezier(0.9, -0.2, 0.1, 1.2);
  }
  .yo-sidebar--expanded {
    flex-basis: 50%;
  }
}
.yo-toaster {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.yo-main {
  flex: 1 1 65%;
  padding: 1em;
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
}

.yo-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: rgb(16, 16, 16);
  color: var(--text-primary);
}

.yo-page__content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  min-height: 0;
}

@media only screen and (min-width: 1023px) {
  .yo-main {
    min-height: 100%;
    overflow-y: auto;
  }
  .yo-page__content {
    flex-direction: row;
  }
}
.yo-navbar {
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent);
  padding: 0.5rem 1.25rem;
  text-align: right;
}

.yo-navbar__links {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.yo-navbar__link {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(255, 255, 255);
  color: var(--text-white);
  margin: 0 1em;
  text-decoration: none;
  transition: opacity 80ms ease-in-out;
}
.yo-navbar__link::first-letter {
  text-transform: uppercase;
}
.yo-navbar__link:active, .yo-navbar__link:hover, .yo-navbar__link--active {
  opacity: 0.9;
}

.yo-navbar__link-disabled,
.yo-navbar__link-disabled:active,
.yo-navbar__link-disabled:hover {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  cursor: default;
  color: rgba(255, 255, 255, .95);
  color: var(--text-accent--light);
  -webkit-filter: saturate(20%);
          filter: saturate(20%);
  margin: 0 1em;
  opacity: 0.2;
}
.yo-navbar__link-disabled::first-letter,
.yo-navbar__link-disabled:active::first-letter,
.yo-navbar__link-disabled:hover::first-letter {
  text-transform: uppercase;
}

.yo-navbar__link-button {
  color: rgba(255, 255, 255, .95);
  color: var(--text-accent--light);
  display: inline-block;
  height: 2.5rem;
  margin: 1rem 0 0.5rem 2rem;
  padding: 0.75em 1.25rem;
  transition: opacity 80ms ease-in-out;
}
.yo-navbar__link-button:active, .yo-navbar__link-button:hover, .yo-navbar__link-button--active {
  opacity: 1;
}
.yo-navbar__link-button svg {
  fill: rgb(255, 0, 90);
  fill: var(--background-accent--bright);
}

.yo-navbar__save-button {
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent--light);
}
.yo-navbar__save-button:hover {
  background-color: rgb(0, 95, 105);
  background-color: var(--background-accent--dark);
}
.yo-navbar__save-button--active {
  background-color: rgb(0, 120, 130);
  background-color: var(--background-accent);
}
.yo-navbar__save-button--active:hover {
  background-color: rgb(0, 95, 105);
  background-color: var(--background-accent--dark);
}

@media only screen and (min-width: 480px) {
  .yo-navbar__link-button {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0 0 2rem;
  }
}
.confirm-dialog-buttons {
  margin-bottom: 1rem;
}
.yo-spaced-group {
  display: flex;
  flex-direction: row;
}
.yo-spaced-group > :not(:last-child) {
  margin-right: 8px;
}
.yo-spaced-group--wrap {
  flex-wrap: wrap;
}
.yo-spaced-group--center {
  align-items: center;
}
.yo-spaced-group--top-spacing {
  margin-top: 8px;
}
.yo-spaced-group--bottom-spacing {
  margin-bottom: 8px;
}
.yo-confirm-dialog-buttons {
  padding-bottom: 16px;
  padding-right: 8px;
  justify-content: flex-end;
}
.yo-node-menu__items {
  margin: 0 -1em;
}
.yo-node-menu__item {
  border-bottom: 1px solid rgba(128, 128, 128, .2);
  border-bottom: 1px solid var(--border-primary);
  border-top: 1px solid rgb(255, 255, 255);
  border-top: 1px solid var(--border-secondary);
  cursor: pointer;
  outline: 0;
  padding: 0 1em;
}
.yo-node-menu__item:active, .yo-node-menu__item:hover {
  background: rgba(160, 160, 160, .1);
  background: var(--background-primary--dark);
}
.yo-node-menu__item:first-of-type {
  border-top: 0;
  padding-top: 0.25em;
}
.yo-node-menu__item:last-of-type {
  border-bottom: 0;
  padding-bottom: 0.25em;
}

.yo-node-menu__item-description {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(96, 96, 96);
  color: var(--text-secondary--dark);
  font-weight: 300;
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  pointer-events: none;
}

.yo-node-menu__item-name {
  font-weight: 500;
  margin-bottom: 0.25rem;
  margin-top: 0.75rem;
  pointer-events: none;
}
.yo-wizard {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yo-wizard__heading {
  margin: 2rem 0;
  text-align: center;
}

.yo-wizard__create-buttons {
  margin: 2rem 0;
  text-align: center;
}

.yo-wizard__exam-type {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yo-settings__exam-type-selection {
  border: 1px dashed rgba(128, 128, 128, .2);
  border: 1px dashed var(--border-primary);
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  cursor: pointer;
  display: flex;
  margin: 0.5rem;
  padding: 1em;
  width: 50%;
}
.yo-settings__exam-type-selection:active:not(.yo-settings__exam-type-selection--current), .yo-settings__exam-type-selection:hover:not(.yo-settings__exam-type-selection--current) {
  color: rgb(16, 16, 16);
  color: var(--text-primary);
}
.yo-settings__exam-type-selection--current {
  color: rgb(0, 95, 105);
  color: var(--text-accent--dark);
  cursor: default;
}

.yo-settings__exam-type-selection-input {
  margin-right: 2rem;
}

.yo-settings__exam-type-selection-text-wrapper {
  display: block;
}

.yo-settings__exam-type-selection-name {
  font-weight: bold;
  display: block;
}

.yo-settings__exam-type-selection-description {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
}
.yo-settings__exam-type-selection-description-link {
  color: inherit;
}
.yo-question-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 0;
  min-width: 0;
}
.yo-question-list--centered {
  align-items: center;
}
.yo-question-list > .yo-exam-question {
  border: 0;
}

.yo-question-list__paragraph {
  margin-bottom: 2em;
}

.yo-question-list__progressbar {
  margin-bottom: 2em;
  width: 100%;
}

.yo-question-list__question-item {
  border-bottom: 1px solid rgb(238, 238, 238);
  border-bottom: 1px solid var(--border-accent--light);
  padding: 1rem;
}
.yo-question-list__question-item:first-child {
  padding-top: 0;
}
.yo-question-list__question-item:last-child {
  border-bottom: 0;
}

.yo-question-list__exam-link {
  color: rgb(0, 120, 130);
  color: var(--text-accent);
  text-decoration: none;
}
.yo-question-list__exam-link:hover {
  color: rgb(0, 95, 105);
  color: var(--text-accent--dark);
  text-decoration: underline;
}

.yo-question-list__order-number {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}

.yo-question-header {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
  display: block;
  margin-bottom: 0.1em;
  margin-top: 0.67em;
}

.yo-question-header__info {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  margin-left: 0.3rem;
}
.yo-secondary-header {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
}
.yo-settings__publish-description {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
}

.yo-settings__selection--year-semester {
  display: flex;
  flex-wrap: wrap;
}

.yo-settings__select {
  flex: 1 0 auto;
}
.yo-settings__select--year {
  margin-right: 1em;
}
.yo-settings__select label {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.yo-settings__select select {
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
}

.yo-settings__delete-answers-button {
  white-space: nowrap;
}

.yo-settings__delete-answers {
  display: flex;
  flex-direction: row;
}

.yo-settings__delete-answers-button-description {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
}

.yo-settings-enter {
  height: 0.001rem;
  opacity: 0.01;
}

.yo-settings-enter-active {
  height: 3.25rem;
  opacity: 1;
  transition: height 120ms ease-in, opacity 120ms ease-in;
}

.yo-settings-exit {
  height: 3.25rem;
  opacity: 1;
}

.yo-settings-exit-active {
  height: 0.001rem;
  opacity: 0.01;
  transition: height 120ms ease-in, opacity 120ms ease-in;
}
.yo-settingsform {
  margin: 0 auto;
  margin-bottom: 12em; /* Quick fix for issue #1143 */
}

.yo-settingsform--loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1023px) {
  .yo-settingsform {
    max-width: 22em;
  }
}
.yo-sharing-input__image {
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
  margin-bottom: 2em;
  text-align: center;
}
.yo-sharing-input__image--id {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  margin-bottom: 0.5em;
}
.yo-sharing-input__image--preview {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  max-width: 40%;
}

.yo-sharing-input__description {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
}

.yo-sharing-input__required {
  color: rgb(204, 0, 17);
  color: var(--text-accent--error);
  margin-left: 0.25em;
}
.yo-share-preview__wrapper {
  margin-top: 2em;
  max-width: 80%;
}

.yo-share-preview__container {
  border: 2px solid rgba(128, 128, 128, .2);
  border: 2px solid var(--border-primary);
}

.yo-share-preview__title {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  margin-left: 0.5em;
  margin-top: 1em;
}

.yo-share-preview__description {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  margin-bottom: 1em;
  margin-left: 0.75em;
  margin-top: 0.25em;
}
.yo-reviews {
  margin: 0 auto;
  max-width: 80rem;
  padding-bottom: 3rem;
}

.yo-reviews__review {
  background: rgb(248, 248, 248);
  background: var(--background-secondary);
  flex: 1 1;
  padding: 1rem;
}

.yo-reviews__scores {
  display: flex;
  justify-content: flex-end;
}

.yo-reviews__scores-input {
  flex-basis: 15%;
  margin-right: 2em;
}

.yo-reviews__icons {
  flex-basis: 70%;
}

.yo-reviews__icon-delete {
  float: right;
}

.yo-reviews__container {
  border: 1px solid rgba(96, 96, 96, .2);
  border: 1px solid var(--border-primary--dark);
  display: flex;
  margin-top: 2em;
}

.yo-reviews__preview {
  flex: 1 1;
  margin: 1em;
}
.yo-answers__form {
  display: block;
  margin: 0 auto;
  max-width: 75em;
  overflow: hidden;
  overflow-x: scroll;
}

.yo-answers__table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
}
.yo-answers__table caption {
  caption-side: bottom;
}
.yo-answers__table tbody tr:nth-child(even) {
  background-color: rgb(235, 240, 240);
  background-color: var(--background-accent--secondary);
}
.yo-answers__table tbody th {
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
}
.yo-answers__table tbody td {
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
  text-align: center;
  padding: 0.5em 0;
  min-width: 9.5em;
}
.yo-answers__table thead tr th {
  vertical-align: bottom;
}

.yo-answers--offset-buttons {
  margin: 0 auto;
}

.yo-answers--table-contact-link {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
}

.yo-answers--logs__spinner-wrapper {
  display: flex;
  align-content: space-between;
  justify-content: center;
}

.yo-answers__no-answers {
  text-align: center;
}
.yo-publishform {
  margin: 0 auto;
}

@media only screen and (min-width: 1023px) {
  .yo-publishform {
    max-width: 22em;
  }
}
.yo-sharing-review__wrapper {
  margin-top: 16px;
}

.yo-sharing-review__icons {
  display: flex;
  justify-content: space-between;
  width: 150px;
}
.yo-exam-review {
  margin-bottom: 32px;
}
.yo-exam-review__average {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  font-style: italic;
  margin-top: 0.5em;
}
.yo-node-score__time-question {
  font-style: italic;
  font-weight: normal;
}
.yo-thanks-for-voting {
  color: rgb(0, 120, 130);
  color: var(--text-accent);
  font-weight: bold;
  margin-top: 16px;
}
.yo-fragment__content {
  width: 100%;
  overflow-wrap: anywhere;
}

.yo-fragment__content h3 {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
}

.yo-fragment__text {
  margin-bottom: 8px;
}

.yo-fragment__display-number {
  display: flex;
  margin-bottom: 8px;
}
.yo-disclaimer {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  margin: 8px 0 16px 0;
}
.yo-disclaimer > *:not(:last-child) {
  margin-bottom: 8px;
}

.yo-disclaimer__login {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
}
.yo-tunnus-login-link {
  text-decoration: underline;
  cursor: pointer;
}

.yo-tunnus-login-icon-path {
  fill: rgb(16, 16, 16);
  fill: var(--text-primary);
}
@charset "UTF-8";
.yo-toc {
  margin-top: 1em;
}

.yo-toc-header {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 0.5em;
  outline: 0;
  -webkit-user-select: none;
          user-select: none;
}

.yo-toc-header__icon {
  transition: -webkit-transform 333ms cubic-bezier(0.9, -0.33, 0.1, 1.33);
  transition: transform 333ms cubic-bezier(0.9, -0.33, 0.1, 1.33);
  transition: transform 333ms cubic-bezier(0.9, -0.33, 0.1, 1.33), -webkit-transform 333ms cubic-bezier(0.9, -0.33, 0.1, 1.33);
}
.yo-toc-header__icon--expanded {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.yo-toc-header__icon svg {
  fill: rgb(118, 118, 118);
  fill: var(--text-secondary);
}

.yo-toc-items {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
}

.yo-toc-items-enter {
  max-height: 0.001rem;
  opacity: 0.01;
}

.yo-toc-items-enter-active {
  max-height: 60rem;
  opacity: 1;
  transition: max-height 120ms ease-in, opacity 120ms ease-in;
}

.yo-toc-items-exit {
  max-height: 60rem;
  opacity: 1;
}

.yo-toc-items-exit-active {
  max-height: 0.001rem;
  opacity: 0.01;
  transition: max-height 120ms ease-in, opacity 120ms ease-in;
}

.yo-toc-item {
  color: rgb(16, 16, 16);
  color: var(--text-primary);
  margin: 0 2em 1.5em;
}

.yo-toc-item__text {
  cursor: pointer;
  outline: 0;
}

.yo-toc-item__suffix--material-essay {
  text-transform: lowercase;
}
.yo-toc-item__suffix--material-essay::after {
  content: ")";
}
.yo-toc-item__suffix--material-essay::before {
  content: " (";
}
.yo-toc-item__suffix--material::after, .yo-toc-item__suffix--title-essay::after {
  content: ", ";
}
.yo-toc-item__suffix--material:last-of-type::after, .yo-toc-item__suffix--title-essay:last-of-type::after {
  content: "";
}
.yo-toc-item__suffix--questiontype::before {
  content: " \2013";
}

@media only screen and (min-width: 480px) {
  .yo-toc-item {
    margin-bottom: 1em;
  }
}
.yo-exam-logo {
  align-items: center;
  cursor: default;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  -webkit-user-select: none;
          user-select: none;
}

.yo-exam-logo__image {
  margin-right: 0.5em;
}
.yo-exam-logo__image > .yo-icon {
  max-width: 5rem;
}
.yo-exam-logo__image > .yo-icon svg {
  fill: rgb(118, 118, 118);
  fill: var(--text-secondary);
}

.yo-exam-logo__text {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
}

.yo-exam-logo__text-row {
  display: block;
}

@media only screen and (min-width: 480px) {
  .yo-exam-logo {
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-bottom: 1em;
  }
  .yo-exam-logo__image {
    margin-right: 1em;
  }
  .yo-exam-logo__image > .yo-icon {
    max-width: initial;
  }
}
.yo-exam-info__carousel {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yo-exam-info {
  margin-bottom: 16px;
}
.yo-exam-info h2.yo-primary-header {
  margin: 0;
  padding: 4px 0;
}
.yo-vote-results-filter {
  padding-left: 16px;
}
.yo-exam-root-authors {
  padding-top: 0.5rem;
  text-align: right;
}

.yo-exam-root-authors__author-link {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(16, 16, 16);
  color: var(--text-primary);
  font-style: italic;
  padding-right: 0.25rem;
  text-decoration: none;
}
.yo-exam-root-authors__author-link::after {
  content: ", ";
  display: inline-block;
}
.yo-exam-root-authors__author-link:last-of-type::after {
  display: none;
}

.yo-exam-root__editor-display-settings-container {
  margin-bottom: 16px;
}
.yo-option-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  background-color: var(--yds-color-background-interactive);
  border: 2px solid var(--yds-color-background-interactive);
  border-radius: 8px;
  padding: 0 16px;
  min-height: 48px;
  width: 100%;
  text-align: left;
  word-break: break-word;
}
.yo-option-button--selected {
  border: 2px solid var(--yds-color-border);
}
.yo-option-button--wrong {
  border-color: var(--yds-color-feedback-error);
}
.yo-option-button--correct {
  border-color: var(--yds-color-feedback-success);
}
.yo-option-button--clickable {
  cursor: pointer;
}
.yo-option-button--clickable:hover {
  background-color: var(--yds-color-action-secondary-variant);
  border: 2px solid var(--yds-color-border);
}
.yo-combine-question {
  display: flex;
  flex-direction: column;
}
.yo-combine-question > *:not(:last-child) {
  margin-bottom: 8px;
}

.yo-combine-question__detach {
  align-self: center;
}

.yo-combine-question__correct-answers {
  margin: 0 16px;
}
.yo-combine-question__correct-answers > *:not(:last-child) {
  margin-bottom: 8px;
}

.yo-combine-question__correct-answers-text {
  margin-top: 16px;
}
.yo-order-question > *:not(:last-child) {
  margin-bottom: 8px;
}

.yo-order-question__selected {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 767px) {
  .yo-order-question__selected {
    flex-direction: column;
  }
}

.yo-order-question__options {
  flex: 1 1;
}
.yo-order-question__options > *:not(:last-child) {
  margin-bottom: 8px;
}

.yo-order-question__options--selected {
  flex: 1 1;
}
.yo-order-question__options--selected > *:not(:last-child) {
  margin-bottom: 8px;
}
@media only screen and (max-width: 767px) {
  .yo-order-question__options--selected:nth-child(2) {
    margin-top: 16px;
  }
}
@media only screen and (min-width: 480px) {
  .yo-order-question__options--selected:last-child {
    margin-left: 12px;
  }
}

.yo-multiple-choice-question__option-points {
  margin-left: 8px;
  color: var(--yds-color-text-medium-emphasis);
}

.yo-order-question__option--wrapper {
  display: flex;
  align-items: center;
}
.yo-order-question__option--index {
  display: inline-block;
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
  height: 100%;
  vertical-align: middle;
  margin-right: 12px;
}
.yo-gap-question {
  counter-reset: gap-counter;
}
.yo-multiple-choice-question__options {
  grid-gap: 8px;
  display: grid;
}
.yo-multiple-choice-question__options .yo-image__captions > .yo-image__copyright {
  color: rgb(16, 16, 16);
  color: var(--text-primary);
  padding: 8px 16px;
  margin: 0;
}

@media only screen and (min-width: 480px) {
  .yo-multiple-choice-question__options--two-columns {
    grid-template-columns: 1fr 1fr;
  }
}
.yo-multiple-choice-question__show-poll-results {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
}
.yo-multiple-choice-question__show-poll-results:hover {
  cursor: pointer;
  text-decoration: underline;
}

.choice-button-stats-container {
  margin: 0 16px;
}
.yo-vote-question__vote-ended {
  margin-bottom: 1em;
}

.yo-vote-question__show_results {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  margin-top: 1em;
}
.yo-vote-question__show_results:hover {
  cursor: pointer;
  text-decoration: underline;
}

.yo-vote-results__total-count {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  margin-top: 1em;
}

.yo-vote-results__updated-at {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
}

.yo-vote-question__option {
  display: flex;
  align-items: center;
}

.yo-vote-question__free-text-input {
  margin: 0 0 0 8px;
  width: 15em;
}

.free-text-input-container {
  margin: 0 16px 12px;
}
.yo-progressbar {
  background: var(--yds-color-border-separator);
  border-radius: 2em;
  overflow: hidden;
  width: 100%;
}

.yo-progressbar__progress {
  background-color: var(--yds-color-player-progress-indicator);
  border-radius: 2em;
  display: block;
  height: 100%;
  transition: width 0.2s linear;
}

.yo-progressbar__progress-text {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  display: block;
}
.yo-progressbar__progress-text--grey {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
}
.yo-text-results {
  width: 100%;
}

.yo-text-results-buttons {
  text-align: center;
}

.yo-answers-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
}

.yo-answers-table-tr {
  text-align: left;
}
.yo-answers-table-tr th {
  padding: 8px 8px;
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
}
.yo-answers-table-tr th:nth-child(2) {
  width: 6em;
}
.yo-answers-table-tr td {
  padding: 4px 8px;
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.yo-answers-table-contact-link {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
}
.yo-scored-vote-question__selected {
  display: flex;
}
.yo-scored-vote-question__selected--column {
  flex: 1 0;
  flex-direction: column;
}

.yo-scored-vote-question__options > *:first-child {
  margin-top: 8px;
}
.yo-scored-vote-question__options > *:not(:last-child) {
  margin-bottom: 8px;
}

.yo-scored-vote-question__info {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.yo-scored-vote-question__info-button {
  margin: 8px;
}

.yo-scored-vote-question__stats {
  display: flex;
  flex-direction: column;
}

.yo-scored-vote-question__option--wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}
.yo-scored-vote-question__option--index {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
  margin-right: 12px;
}

.yo-scored-vote-results {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
}
.yo-scored-vote-results__option-result {
  margin-top: 4px;
  margin-bottom: 4px;
}
.yo-scored-vote-results__option-result-title {
  display: block;
}
.yo-scored-vote-results__option-result-values {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  margin-left: 8px;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
}
.yo-timer__wrapper {
  margin-bottom: 1em;
}

.yo-timer__second {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(0, 181, 200);
  color: var(--background-accent--yle);
  text-align: center;
}
.yo-timer-start__button {
  text-align: center;
}

.yo-timer-start__invalid-duration {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(204, 0, 17);
  color: var(--text-accent--error);
  margin-top: 0.8em;
}
.yo-poll-results__label-selected {
  font-weight: bold;
}

.yo-poll-results__option {
  margin-bottom: 1px;
}

.yo-poll-results__image {
  margin-bottom: 0.25em;
  max-width: 60%;
}
.yo-poll-results__image.yo-poll-results__label-selected {
  border: 2px dashed rgba(0, 95, 105, .5);
  border: 2px dashed var(--border-accent);
  padding: 0.2em;
}

.yo-poll-results__total-count {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
}

@media only screen and (min-width: 480px) {
  .yo-poll-results__image {
    max-width: 30%;
  }
}
.yo-node-placeholder {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  opacity: 0.5;
  cursor: pointer;
  outline: 0;
  padding: 0.75rem 0;
  position: relative;
  text-align: center;
  z-index: 0;
}
.yo-node-placeholder::after {
  border-top: 1px solid rgba(96, 96, 96, .2);
  border-top: 1px solid var(--border-primary--dark);
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
}
.yo-node-placeholder:active, .yo-node-placeholder:hover {
  color: rgb(96, 96, 96);
  color: var(--text-secondary--dark);
  opacity: 1;
}

.yo-node-placeholder__title {
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
  padding: 0 0.5rem;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
.yo-node-editable__title {
  align-items: center;
  display: inline-flex;
}
.yo-node-editable__title svg {
  fill: rgb(192, 192, 192);
  fill: var(--text-secondary--light);
}
.yo-node-review__tags {
  align-items: baseline;
  display: flex;
  margin: 0 0 32px;
}
.yo-node-review__tags .yo-tags {
  margin: 0;
}
.yo-node-review__tags .yo-tags__tag {
  margin-right: 8px;
}
.yo-node-review__tags .yo-tags__tag::after {
  content: ",";
}
.yo-node-review__tags .yo-tags__tag:last-of-type::after {
  content: initial;
}
.yo-node-review__tags .yo-tags__tag-title {
  padding: 0;
}

.yo-node-review__tunnus-disclaimer {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  font-style: italic;
  text-align: center;
}

.yo-node-review__tag-title {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(32, 32, 32);
  color: var(--text-primary--light);
  margin-right: 8px;
}
.yo-node-review__tag-title::after {
  content: ":";
}

.yo-node-review__tag-title-clickable:hover {
  color: rgb(0, 120, 130);
  color: var(--text-accent);
  cursor: pointer;
}

.yo-node-review-enter {
  height: 0.001rem;
  opacity: 0.01;
}

.yo-node-review-enter-active {
  height: 20px;
  opacity: 1;
  transition: height 120ms ease-in-out, opacity 120ms ease-in-out 120ms;
}

.yo-node-review-exit {
  height: 20px;
  opacity: 1;
}

.yo-node-review-exit-active {
  height: 0.001rem;
  opacity: 0.01;
  transition: height 120ms ease-in-out 120ms, opacity 120ms ease-in-out;
}

.yo-node-review {
  margin-top: 16px;
}
.yo-node-review .yo-node-review__feedback-link {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  color: rgb(192, 192, 192);
  color: var(--text-secondary--light);
  margin-left: auto;
  padding-left: 16px;
  text-decoration: none;
}
.yo-node-review .yo-node-review__feedback-link:hover {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  text-decoration: underline;
}

@media only screen and (min-width: 480px) {
  .yo-node-review__tunnus-disclaimer {
    text-align: right;
  }
}
.yo-node-review-notification-content {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
}

.yo-node-review-notification-feedback {
  display: grid;
  grid-gap: 8px;
  gap: 8px;
}
.yo-exam-question:not(:last-child) {
  margin-bottom: 32px;
}

.yo-exam-question__exam-link {
  color: rgb(0, 120, 130);
  color: var(--text-accent);
  cursor: pointer;
}
.yo-exam-question__exam-link:hover {
  color: rgb(0, 95, 105);
  color: var(--text-accent--dark);
  text-decoration: underline;
}

.yo-exam-question__exam-name {
  color: rgb(192, 192, 192);
  color: var(--text-secondary--light);
  margin: 2em 0;
}
.yo-widget {
  margin-bottom: 2.25rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 60rem;
  position: relative;
}
.yo-widget--editing {
  margin-left: initial;
  margin-right: initial;
  max-width: initial;
}
.yo-widget--blurred {
  pointer-events: none;
  opacity: 0.75;
}
.yo-widget--webview {
  margin: 1em;
}

.yo-widget__exam-ended {
  margin-bottom: 8px;
  display: block;
}

.yo-widget__actions-buttons {
  justify-content: flex-end;
}

.yo-widget__login-warning {
  margin-bottom: 16px;
}
.yo-exams {
  padding: 1em 0;
}

.yo-exams__row {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  border-bottom: 1px solid rgba(128, 128, 128, .1);
  border-bottom: 1px solid var(--border-primary--light);
  display: flex;
  flex-direction: column;
  padding: 0.8em 0;
}

.yo-exams__link-text {
  color: rgb(0, 120, 130);
  color: var(--text-accent);
  cursor: pointer;
}
.yo-exams__link-text:hover {
  color: rgb(0, 95, 105);
  color: var(--text-accent--dark);
}

.yo-exams__header {
  display: none;
}

.yo-exams__item {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  display: flex;
  padding: 0.4em 0.5em;
}
.yo-exams__item::before {
  content: attr(data-column-title);
  font-weight: 600;
  width: 5em;
}

@media only screen and (min-width: 1023px) {
  .yo-exams__header {
    border-bottom: 1px solid rgba(128, 128, 128, .1);
    border-bottom: 1px solid var(--border-primary--light);
    display: flex;
    flex-direction: row;
    font-weight: 700;
    margin: 0 0.15em;
    margin-bottom: 0.25em;
    padding: 0.1rem 0;
  }
  .yo-exams__row {
    flex-direction: row;
  }
  .yo-exams__item {
    font-family: "Yle Next", "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .yo-exams__item::before {
    display: none;
  }
  .yo-exams__item--name {
    flex-basis: 400px;
  }
  .yo-exams__item--score {
    flex-basis: 200px;
  }
  .yo-exams__item--progress {
    flex-basis: 100px;
  }
}
.yo-series-info {
  margin: 2.5em auto;
  max-width: 60em;
}
.yo-series-info .yo-accordion-item__header {
  border: 1px solid rgba(0, 95, 105, .5);
  border: 1px solid var(--border-accent);
}
.yo-series-info .yo-accordion-item__title {
  color: rgb(0, 95, 105);
  color: var(--text-accent--dark);
}

.yo-series-info__desc {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  margin-bottom: 1em;
}

.yo-series-info__content {
  padding: 0 0.5em;
}

.yo-series-info__wrapper {
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
  padding: 1em;
}

.yo-series-info__promote {
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  font-style: italic;
  margin-top: 1em;
}
.yo-series-info__promote-link {
  background: none;
  border: 0;
  color: rgb(0, 181, 200);
  color: var(--background-accent--yle);
  cursor: pointer;
  font-style: italic;
  margin: 0 0 0 0.3em;
  padding: 0;
}
.yo-series-info__promote-link:active, .yo-series-info__promote-link:hover, .yo-series-info__promote-link--active {
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
}
.yo-login-warning {
  margin-top: 16px;
}

.yo-login-warning__link {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  padding-left: 2px;
  text-decoration: underline;
  color: var(--yds-color-text-dark);
}
.yo-exam-logs__log {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.yo-exam-logs__log > .yo-exam-logs__log-col {
  flex: 1 1;
  min-height: 2em;
  padding: 0.5em 0;
  text-align: center;
}
.yo-exam-logs__log > .yo-exam-logs__log-col--message {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  flex: 2 1;
}
.yo-exam-logs__log:nth-child(2n+1) {
  background: rgb(248, 248, 248);
  background: var(--background-secondary);
}

.yo-exam-logs__logs-header {
  border-bottom: 2px solid rgba(128, 128, 128, .1);
  border-bottom: 2px solid var(--border-primary--light);
  font-weight: bold;
}

.yo-exam-logs__response-icon svg {
  fill: rgb(120,185,40);
  fill: var(--fill-success);
}
.yo-exam-logs__response-icon--incorrect svg {
  fill: rgb(255, 0, 90);
  fill: var(--fill-error);
}

.yo-exam-logs__spinner-wrapper {
  display: flex;
  align-content: space-between;
  justify-content: center;
}
.yo-characters {
  background: rgb(253, 253, 253);
  background: var(--background-primary);
  margin: 0 auto;
  overflow-x: auto;
}
.yo-characters__add {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  margin-top: 1em;
}
.yo-characters__container {
  display: flex;
}

.yo-character-question {
  padding-bottom: 1em;
}

.yo-characters-title__container {
  display: flex;
  padding: 0 8px;
  max-width: 165px;
  min-width: 165px;
}
.yo-characters-title__container-first {
  margin-left: 165px;
}

.yo-characters-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1;
}

.yo-character-important {
  margin-top: 3em;
  padding-bottom: 0.5em;
}
@media only screen and (min-width: 1023px) {
  .yo-character-important {
    margin-top: 2.5em;
  }
}
.yo-character-important__checkbox {
  margin: 1em 0;
  width: 6.5em;
}
@media only screen and (min-width: 1023px) {
  .yo-character-important__checkbox {
    margin: 0.5em 0;
  }
}

.yo-character-choice {
  margin-bottom: 1.5em;
  margin-top: 0.1em;
  padding-bottom: 0.1em;
}
@media only screen and (min-width: 1023px) {
  .yo-character-choice {
    margin-bottom: 1.2em;
    margin-top: 0.3em;
  }
}

.yo-character-question__wrapper {
  display: flex;
}

.yo-characters-labels {
  width: 165px;
}
.yo-characters-labels__item {
  flex-basis: 100%;
  margin-right: 1em;
  min-height: 2em;
  overflow: hidden;
  padding: 0.5em 0;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media only screen and (min-width: 1023px) {
  .yo-characters-labels__item {
    padding: 0.25em 0;
  }
}
.yo-characters-labels__question {
  font-weight: bold;
}

.yo-character-edit {
  margin: 0 auto;
  max-width: 40rem;
}
.yo-character-edit__button-container {
  display: flex;
  justify-content: center;
}
.yo-character-edit__button {
  padding: 0.5rem 2rem;
}
.yo-character-edit__container {
  display: flex;
}
.yo-character-edit__preview {
  margin: 0 2em;
}
.yo-character-edit__editor {
  background: rgb(248, 248, 248);
  background: var(--background-secondary);
  padding: 1em;
}

.yo-character-preview__wrapper {
  border: 1px solid rgba(128, 128, 128, .2);
  border: 1px solid var(--border-primary);
  padding: 1em;
}

.yo-character-choices {
  padding-left: 8px;
  width: 165px;
}

.yo-characters-header {
  display: flex;
}

.yo-characters__sharing {
  border-top: 1px solid rgba(128, 128, 128, .2);
  border-top: 1px solid var(--border-primary);
  margin-top: 1em;
  padding-top: 1em;
}
.yo-series {
  margin: 0 auto;
  max-width: 40rem;
}
.yo-series-list {
  margin: auto;
  max-width: 60em;
}

.yo-series-list__header {
  border-bottom: 1px solid rgba(128, 128, 128, .1);
  border-bottom: 1px solid var(--border-primary--light);
  display: flex;
  font-weight: 700;
  justify-content: space-between;
}

.yo-series-list__row {
  border-bottom: 1px solid rgba(128, 128, 128, .1);
  border-bottom: 1px solid var(--border-primary--light);
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
}

.yo-series-list__item--updated {
  margin: 0 1em;
  width: 100%;
}
.yo-series-list__item--action {
  margin: 0 1em;
  width: 50%;
}
.yo-series-list__item--name {
  width: 100%;
}
.yo-series-list__item--name a {
  color: rgb(16, 16, 16);
  color: var(--text-primary);
  text-decoration: none;
}
.yo-series-list__item--name a:active, .yo-series-list__item--name a:hover {
  color: rgb(0, 120, 130);
  color: var(--text-accent);
}

.yo-series-container__create {
  margin-bottom: 2em;
}

@media only screen and (min-width: 480px) {
  .yo-series-list__item--updated {
    width: 75%;
  }
  .yo-series-list__item--action {
    margin: 0 1em;
    width: 20%;
  }
  .yo-series-list__item--name {
    margin-left: 1em;
    width: 100%;
  }
}
.yo-series-container {
  margin: auto;
  max-width: 50em;
}

.yo-series-container__desc {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  background-color: rgb(248, 248, 248);
  background-color: var(--background-secondary);
  border: 1px solid rgba(128, 128, 128, .1);
  border: 1px solid var(--border-primary--light);
  color: rgb(118, 118, 118);
  color: var(--text-secondary);
  margin-bottom: 2em;
  padding: 1em;
}

.yo-series-container__create {
  text-align: center;
}

.yo-series-container__edit-order {
  margin: 1em;
  text-align: center;
}

.yo-series-container__icon-buttons {
  display: flex;
  justify-content: flex-end;
}
.yo-series-exam-order__description {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  margin: 1em 0;
}
.yo-series-exam-order__row {
  align-items: center;
  border: 1px dashed rgba(96, 96, 96, .2);
  border: 1px dashed var(--border-primary--dark);
  border-radius: 4px;
  display: flex;
  margin-bottom: 0.5em;
  padding: 0.5em;
  transition: background-color 0.1s;
}
.yo-series-exam-order__row:hover {
  background-color: rgb(235, 240, 240);
  background-color: var(--background-accent--secondary);
}
.yo-series-exam-order__order {
  margin-right: 1em;
  width: 3em;
}
.yo-series-exam-order__name {
  flex: 1 1;
}
.yo-series-exam-order__published {
  flex: 1 1;
}
.yo-series-exam-order__header {
  border: 0;
  font-weight: bold;
}
.yo-series-exam-order__header:hover {
  background-color: transparent;
}
.yo-series-exam-order__dragging {
  background-color: rgb(235, 240, 240);
  background-color: var(--background-accent--secondary);
}
.yo-app,
.yo-tehtava {
  --background-primary: rgb(255, 255, 255);
  --background-primary--dark: rgba(160, 160, 160, .1);
  --background-primary--xdark: rgb(64, 64, 64);
  --background-secondary: rgb(248, 248, 248);
  --background-secondary--light: rgb(253, 253, 253);
  --background-disabled: rgb(241, 241, 241);
  --background-accent: rgb(0, 120, 130);
  --background-accent--bright: rgb(255, 0, 90);
  --background-accent--dark: rgb(0, 95, 105);
  --background-accent--error: rgba(255, 232, 230, .2);
  --background-accent--highlight: rgba(255, 250, 238, .5);
  --background-accent--light: rgb(0, 120, 130);
  --background-accent--notification: rgba(0, 120, 130, .05);
  --background-accent--score: rgba(0, 120, 130, .08);
  --background-accent--secondary: rgb(235, 240, 240);
  --background-accent--xbright: rgb(240, 115, 15);
  --background-accent--yle: rgb(0, 181, 200);
  --background-input--correct: rgba(201, 234, 116, .6);
  --background-input--incorrect: rgba(255, 131, 131, .4);
  --text-primary: rgb(16, 16, 16);
  --text-primary--light: rgb(32, 32, 32);
  --text-secondary: rgb(118, 118, 118);
  --text-secondary--light: rgb(192, 192, 192);
  --text-secondary--dark: rgb(96, 96, 96);
  --text-white: rgb(255, 255, 255);
  --text-accent: rgb(0, 120, 130);
  --text-accent--dark: rgb(0, 95, 105);
  --text-accent--error: rgb(204, 0, 17);
  --text-accent--light: rgba(255, 255, 255, .95);
  --border-primary: rgba(128, 128, 128, .2);
  --border-primary--dark: rgba(96, 96, 96, .2);
  --border-primary--light: rgba(128, 128, 128, .1);
  --border-primary--xdark: rgba(32, 32, 32, .2);
  --border-secondary: rgb(255, 255, 255);
  --border-accent: rgba(0, 95, 105, .5);
  --border-accent--light: rgb(238, 238, 238);
  --fill-primary: rgb(0, 120, 130);
  --fill-secondary: rgb(255, 255, 255);
  --fill-success: rgb(120, 185, 40);
  --fill-error: rgb(255, 0, 90);
  --shadow: rgba(128, 128, 128, .1);
  --shadow--dark: rgba(0, 0, 0, .3);
  --yds-color-action-secondary-variant: rgba(0, 0, 0, 0.09);
  --yds-color-background-interactive: #f1f2f4;
  --yds-color-border: #131415;
  --yds-color-border-separator: #e8e9eb;
  --yds-color-feedback-error: #d10b3d;
  --yds-color-feedback-success: #058657;
  --yds-color-feedback-highlight: #009cb5;
  --yds-color-text-default: #131415;
  --yds-color-text-medium-emphasis: rgba(0, 0, 0, 0.7);
  --yds-color-background-control-disabled: rgba(0, 0, 0, 0.15);
  --yds-color-player-progress-indicator: #29ccde;
  --yds-color-text-dark: #131415;
}

@media (prefers-color-scheme: dark) {
  .yo-app,
  .yo-tehtava--auto-dark {
    --background-primary: #131415;
    --background-primary--dark: rgba(160, 160, 160, .1);
    --background-primary--xdark: rgb(64, 64, 64);
    --background-secondary: rgb(39, 42, 44);
    --background-secondary--light: rgb(40, 44, 46);
    --background-disabled: rgb(36, 42, 45);
    --background-accent: rgb(0, 100, 110);
    --background-accent--dark: rgb(0, 75, 85);
    --background-accent--highlight: rgb(34, 37, 39);
    --background-accent--secondary: rgb(55, 60, 60);
    --background-input--correct: rgb(72, 87, 21);
    --background-input--incorrect: rgb(77, 25, 26);
    --text-primary: rgb(249, 249, 249);
    --text-primary--light: rgb(233, 233, 233);
    --text-secondary: rgb(185, 185, 185);
    --text-secondary--light: rgb(140, 140, 140);
    --text-secondary--dark: rgb(220, 220, 220);
    --text-accent: rgb(71, 236, 255);
    --text-accent--dark: rgb(0, 230, 250);
    --fill-primary: rgb(71, 236, 255);
    --fill-secondary: rgb(0, 0, 0);
    --shadow--dark: rgba(0, 0, 0, 1);
    --yds-color-action-secondary-variant: rgba(255, 255, 255, 0.17);
    --yds-color-border: #f8f9fa;
    --yds-color-border-separator: #4a4f54;
    --yds-color-background-interactive: #292b2d;
    --yds-color-feedback-error: #f5517a;
    --yds-color-feedback-success: #03e592;
    --yds-color-feedback-highlight: #29ccde;
    --yds-color-text-default: #f8f9fa;
    --yds-color-text-medium-emphasis: rgba(255, 255, 255, 0.71);
    --yds-color-background-control-disabled: rgba(255, 255, 255, 0.21);
    --yds-color-player-progress-indicator: #29ccde;
    --yds-color-text-dark: #131415;
  }
}
.yo-tehtava--force-dark {
  --background-primary: #131415;
  --background-primary--dark: rgba(160, 160, 160, .1);
  --background-primary--xdark: rgb(64, 64, 64);
  --background-secondary: rgb(39, 42, 44);
  --background-secondary--light: rgb(40, 44, 46);
  --background-disabled: rgb(36, 42, 45);
  --background-accent: rgb(0, 100, 110);
  --background-accent--dark: rgb(0, 75, 85);
  --background-accent--highlight: rgb(34, 37, 39);
  --background-accent--secondary: rgb(55, 60, 60);
  --background-input--correct: rgb(72, 87, 21);
  --background-input--incorrect: rgb(77, 25, 26);
  --text-primary: rgb(249, 249, 249);
  --text-primary--light: rgb(233, 233, 233);
  --text-secondary: rgb(185, 185, 185);
  --text-secondary--light: rgb(140, 140, 140);
  --text-secondary--dark: rgb(220, 220, 220);
  --text-accent: rgb(71, 236, 255);
  --text-accent--dark: rgb(0, 230, 250);
  --fill-primary: rgb(71, 236, 255);
  --fill-secondary: rgb(0, 0, 0);
  --shadow--dark: rgba(0, 0, 0, 1);
  --yds-color-action-secondary-variant: rgba(255, 255, 255, 0.17);
  --yds-color-border: #f8f9fa;
  --yds-color-border-separator: #4a4f54;
  --yds-color-background-interactive: #292b2d;
  --yds-color-feedback-error: #f5517a;
  --yds-color-feedback-success: #03e592;
  --yds-color-feedback-highlight: #29ccde;
  --yds-color-text-default: #f8f9fa;
  --yds-color-text-medium-emphasis: rgba(255, 255, 255, 0.71);
  --yds-color-background-control-disabled: rgba(255, 255, 255, 0.21);
  --yds-color-player-progress-indicator: #29ccde;
  --yds-color-text-dark: #131415;
}

.yo-tehtava, .yo-page {
  background-color: rgb(253, 253, 253);
  background-color: var(--background-primary);
  color: rgb(16, 16, 16);
  color: var(--text-primary);
}
.yo-tehtava p, .yo-page p {
  display: inline;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  color: rgb(16, 16, 16);
  color: var(--text-primary);
}

.yo-fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}
.yo-app, #yo-tehtava-body, #yo-tehtava-embed {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  margin: 0;
  padding: 0;
}
.yo-app *, #yo-tehtava-body *, #yo-tehtava-embed * {
  box-sizing: border-box;
}
