@import '../../../constants/scss/everything';

.yo-text-results {
  width: 100%;
}

.yo-text-results-buttons {
  text-align: center;
}

.yo-answers-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid var(--border-primary);
  @include default-s;
}

.yo-answers-table-tr {
  text-align: left;
  th {
    padding: $yds-spacing-8 $yds-spacing-8;
    border: 1px solid var(--border-primary);
  }
  th:nth-child(2) {
    width: 6em;
  }
  td {
    padding: $yds-spacing-4 $yds-spacing-8;
    border: 1px solid var(--border-primary);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.yo-answers-table-contact-link {
  color: var(--text-secondary);
}
