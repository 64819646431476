@import '../../../constants/scss/everything';

.yo-exam-review {
  margin-bottom: $yds-spacing-32;

  &__average {
    color: var(--text-secondary);
    font-style: italic;
    margin-top: .5em;
  }
}
