@import '~@yleisradio/yds-core/lib/tokens/build/scss/_theme-dark.scss';

@mixin dark-colors {
  --background-primary: #{$yds-color-background};
  --background-primary--dark: rgba(160, 160, 160, .1);
  --background-primary--xdark: rgb(64, 64, 64);
  --background-secondary: rgb(39, 42, 44);
  --background-secondary--light: rgb(40, 44, 46);

  --background-disabled: rgb(36, 42, 45);
  --background-accent: rgb(0, 100, 110);
  --background-accent--dark: rgb(0, 75, 85);
  --background-accent--highlight: rgb(34, 37, 39);
  --background-accent--secondary: rgb(55, 60, 60);
  --background-input--correct: rgb(72, 87, 21);
  --background-input--incorrect: rgb(77, 25, 26);

  --text-primary: rgb(249, 249, 249);
  --text-primary--light: rgb(233, 233, 233);
  --text-secondary: rgb(185, 185, 185);
  --text-secondary--light: rgb(140, 140, 140);
  --text-secondary--dark: rgb(220, 220, 220);
  --text-accent: rgb(71, 236, 255);
  --text-accent--dark: rgb(0, 230, 250);

  --fill-primary: rgb(71, 236, 255);
  --fill-secondary: rgb(0, 0, 0);

  // SHADOWS
  --shadow--dark: rgba(0, 0, 0, 1);

  // YDS COLORS
  --yds-color-action-secondary-variant: #{$yds-color-action-secondary-variant};
  --yds-color-border: #{$yds-color-border};
  --yds-color-border-separator: #{$yds-color-border-separator};
  --yds-color-background-interactive: #{$yds-color-background-interactive};
  --yds-color-feedback-error: #{$yds-color-feedback-error};
  --yds-color-feedback-success: #{$yds-color-feedback-success};
  --yds-color-feedback-highlight: #{$yds-color-feedback-highlight};
  --yds-color-text-default: #{$yds-color-text-default};
  --yds-color-text-medium-emphasis: #{$yds-color-text-medium-emphasis};
  --yds-color-background-control-disabled: #{$yds-color-background-control-disabled};
  --yds-color-player-progress-indicator: #{$yds-color-player-progress-indicator};
  --yds-color-text-dark: #{$yds-color-text-dark};
}

@media (prefers-color-scheme: dark) {

  .yo-app,
  .yo-tehtava--auto-dark {
    @include dark-colors;
  }
}

.yo-tehtava--force-dark {
  @include dark-colors;
}
