@import '../../../constants/scss/everything';

.yo-node-placeholder {
  @include default-s;
  opacity: .5;
  cursor: pointer;
  outline: 0;
  padding: .75rem 0;
  position: relative;
  text-align: center;
  z-index: 0;

  &::after {
    border-top: 1px solid var(--border-primary--dark);
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
  }

  &:active,
  &:hover {
    color: var(--text-secondary--dark);
    opacity: 1;
  }
}

.yo-node-placeholder__title {
  background-color: var(--background-primary);
  padding: 0 .5rem;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
