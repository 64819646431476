@import '../../../constants/scss/everything';

.yo-gap-select {
  white-space: nowrap;
}

.yo-gap-select__answer {
  font-weight: bold;
  white-space: initial;

  &::before {
    content: ' ';
  }
}

.yo-gap-select__hint {
  @include default-s;
  color: var(--text-secondary);
  white-space: initial;
}

.yo-gap-select__numbering {
  @include default-s;
  color: var(--text-secondary--dark);

  &::before {
    content: counter(gap-counter) ') ';
    counter-increment: gap-counter;
  }
}

.yo-gap-select__select {
  background-color: var(--background-primary);
  color: var(--text-primary);
  max-width: calc(100% - 2em);

  &--validated {
    max-width: calc(100% - 4em);
  }
}

.yo-fragment__text {
  counter-reset: gap-counter;
}
