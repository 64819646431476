@import '../../../constants/scss/everything';

.yo-timer__wrapper {
  margin-bottom: 1em;
}

.yo-timer__second {
  @include default-s;
  color: var(--background-accent--yle);
  text-align: center;
}
