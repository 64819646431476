@import '../../../constants/scss/everything';

.yo-icon-button {
  cursor: pointer;
  margin: 0 .25rem;
  border: 0;
  background-color: var(--background-primary--dark);

  &:first-of-type {
    margin-left: 0;
  }

  &:active,
  &:hover {
    & .yo-icon-svg-path {
      opacity: .75;
    }
  }
}

.yo-icon-button__icon {
  height: 1rem;
}

.yo-icon-svg-text {
  @include default-xs-bold;
}
