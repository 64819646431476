$mobile-max: 767px;
$tablet-min: 480px;
$desktop-min: 1023px;

@mixin for-tablet-up {
  @media only screen and (min-width: $tablet-min) {
    @content;
  }
}

@mixin for-desktop-up {
  @media only screen and (min-width: $desktop-min) {
    @content;
  }
}

@mixin for-mobile-only {
  @media only screen and (max-width: $mobile-max) {
    @content;
  }
}
