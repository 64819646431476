@import '../../../constants/scss/everything';

.yo-share-preview__wrapper {
  margin-top: 2em;
  max-width: 80%;
}

.yo-share-preview__container {
  border: 2px solid var(--border-primary);
}

.yo-share-preview__title {
  @include default-xl-bold;
  margin-left: .5em;
  margin-top: 1em;
}

.yo-share-preview__description {
  @include default-s;
  color: var(--text-secondary);
  margin-bottom: 1em;
  margin-left: .75em;
  margin-top: .25em;
}
