@charset "UTF-8";
.yo-toc {
  margin-top: 1em;
}

.yo-toc-header {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 0.5em;
  outline: 0;
  user-select: none;
}

.yo-toc-header__icon {
  transition: transform 333ms cubic-bezier(0.9, -0.33, 0.1, 1.33);
}
.yo-toc-header__icon--expanded {
  transform: rotate(180deg);
}
.yo-toc-header__icon svg {
  fill: var(--text-secondary);
}

.yo-toc-items {
  font-family: "Yle Next", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
}

.yo-toc-items-enter {
  max-height: 0.001rem;
  opacity: 0.01;
}

.yo-toc-items-enter-active {
  max-height: 60rem;
  opacity: 1;
  transition: max-height 120ms ease-in, opacity 120ms ease-in;
}

.yo-toc-items-exit {
  max-height: 60rem;
  opacity: 1;
}

.yo-toc-items-exit-active {
  max-height: 0.001rem;
  opacity: 0.01;
  transition: max-height 120ms ease-in, opacity 120ms ease-in;
}

.yo-toc-item {
  color: var(--text-primary);
  margin: 0 2em 1.5em;
}

.yo-toc-item__text {
  cursor: pointer;
  outline: 0;
}

.yo-toc-item__suffix--material-essay {
  text-transform: lowercase;
}
.yo-toc-item__suffix--material-essay::after {
  content: ")";
}
.yo-toc-item__suffix--material-essay::before {
  content: " (";
}
.yo-toc-item__suffix--material::after, .yo-toc-item__suffix--title-essay::after {
  content: ", ";
}
.yo-toc-item__suffix--material:last-of-type::after, .yo-toc-item__suffix--title-essay:last-of-type::after {
  content: "";
}
.yo-toc-item__suffix--questiontype::before {
  content: " –";
}

@media only screen and (min-width: 480px) {
  .yo-toc-item {
    margin-bottom: 1em;
  }
}