@import '../../../constants/scss/everything';

.yo-exam-list {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
}

.yo-exam-list__author {
  &::after {
    content: ', ';
    display: inline;
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
}

.yo-exam-list__button {
  margin: 2em 0;
}

.yo-exam-list__filter {
  & label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  & select {
    background-color: var(--background-primary);
  }
}

.yo-exam-list__filters {
  width: 100%;
}

.yo-exam-list__header {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
}

.yo-exam-list__table-cell {
  display: table;
  padding: .5em;

  &::before {
    content: attr(data-column-title);
    display: table-cell;
    font-weight: 600;
    padding-right: 1em;
    width: 6em;
  }

  &--actions {
    // Move action icons .25rem to the left. .25rem is the amount of padding in Icon components
    & > div {
      margin-left: -.25rem;
    }
  }

  & a {
    color: inherit;
    text-decoration: none;

    &:active,
    &:hover {
      color: var(--text-accent);
    }
  }
}

.yo-exam-list__table-header {
  display: none;
}

.yo-exam-list__table-row {
  border-bottom: 1px solid var(--border-primary--light);
  display: flex;
  flex-flow: column nowrap;
  padding: 2em 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: 0;
  }
}

.yo-exam-list__limit-select {
  margin-top: .75em;
  text-align: center;
}

.yo-exam-list__title--wrapper {
  margin-left: .5em;
  text-align: left;
  width: 15em;

  & .pull-right {
    float: right;
  }
}

.yo-exam-list__publish-title {
  @include default-xs;
  border: 1px solid var(--border-primary--light);
  border-radius: 2px;
  display: inline-block;
  padding: .6em;

  &--active {
    background-color: var(--background-accent);
    color: var(--text-accent--light);
  }
}

.yo-exam-list__pagination--wrapper {
  margin: 1em 0;
  text-align: center;
}

.yo-exam-list__action--wrapper {
  display: flex;
}

.yo-exam-list__search {
  align-items: flex-end;
  display: flex;
}

@include for-desktop-up {
  .yo-exam-list__filter {
    margin: .25em 1em;

    & label {
      align-items: center;
      flex-direction: row;
    }

    & .yo-select__label {
      margin-right: 1rem;
    }
  }

  .yo-exam-list__filters {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1em 0;
  }

  .yo-exam-list__table-header {
    border-bottom: 1px solid var(--border-primary);
    display: flex;
    @include default-m-bold;
  }

  .yo-exam-list__table-cell {
    display: initial;
    flex: 1 1 0;
    outline: 0;
    padding-right: 1em;

    &::before {
      display: none;
    }

    &:last-of-type {
      padding-right: 0;
    }

    &--actions {
      flex-grow: 1;
    }

    &--author {
      flex-grow: 3;
    }

    &--name {
      flex-grow: 4;
    }

    &--updated-at {
      flex-grow: 3;
    }

    & > .yo-icon {
      padding: 0 .25em;
    }
  }

  .yo-exam-list__table-cell--sortable {
    &:hover {
      color: var(--text-accent);
      cursor: pointer;
    }
  }

  .yo-exam-list__table-row {
    align-items: baseline;
    flex-flow: row nowrap;
    padding: .5em 0;
    width: 100%;
  }

  .yo-exam-list__limit-select {
    text-align: right;
  }

  .yo-exam-list__publish-title {
    user-select: none;

    &:hover {
      border-color: var(--border-primary--dark);
      cursor: pointer;
    }
  }

  .yo-exam-list__pagination--wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }
}
