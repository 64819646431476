@import '../../../constants/scss/everything';

.yo-reviews {
  margin: 0 auto;
  max-width: 80rem;
  padding-bottom: 3rem;
}

.yo-reviews__review {
  background: var(--background-secondary);
  flex: 1;
  padding: 1rem;
}

.yo-reviews__scores {
  display: flex;
  justify-content: flex-end;
}

.yo-reviews__scores-input {
  flex-basis: 15%;
  margin-right: 2em;
}

.yo-reviews__icons {
  flex-basis: 70%;
}

.yo-reviews__icon-delete {
  float: right;
}

.yo-reviews__container {
  border: 1px solid var(--border-primary--dark);
  display: flex;
  margin-top: 2em;
}

.yo-reviews__preview {
  flex: 1;
  margin: 1em;
}
