.yo-answers__form {
  display: block;
  margin: 0 auto;
  max-width: 75em;
  overflow: hidden;
  overflow-x: scroll;
}

.yo-answers__table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid var(--border-primary);

  caption {
    caption-side: bottom;
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: var(--background-accent--secondary);
      }
    }

    th {
      border: 1px solid var(--border-primary);
    }

    td {
      border: 1px solid var(--border-primary);
      text-align: center;
      padding: .5em 0;
      min-width: 9.5em;
    }
  }

  thead tr th {
    vertical-align: bottom;
  }
}

.yo-answers--offset-buttons {
  margin: 0 auto;
}

.yo-answers--table-contact-link {
  color: var(--text-secondary);
}

.yo-answers--logs__spinner-wrapper {
  display: flex;
  align-content: space-between;
  justify-content: center;
}

.yo-answers__no-answers {
  text-align: center;
}
