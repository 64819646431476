@import '../../../constants/scss/everything';

.yo-settings__publish-description {
  @include default-s;
}

.yo-settings__selection {
  &--year-semester {
    display: flex;
    flex-wrap: wrap;
  }
}

.yo-settings__select {
  flex: 1 0 auto;

  &--year {
    margin-right: 1em;
  }

  & label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  & select {
    background-color: var(--background-primary);
  }
}

.yo-settings__delete-answers-button {
  white-space: nowrap;
}

.yo-settings__delete-answers {
  display: flex;
  flex-direction: row;
}

.yo-settings__delete-answers-button-description {
  @include default-s;
}

.yo-settings-enter {
  height: .001rem;
  opacity: .01;
}

.yo-settings-enter-active {
  height: 3.25rem;
  opacity: 1;
  transition: height 120ms ease-in, opacity 120ms ease-in;
}

.yo-settings-exit {
  height: 3.25rem;
  opacity: 1;
}

.yo-settings-exit-active {
  height: .001rem;
  opacity: .01;
  transition: height 120ms ease-in, opacity 120ms ease-in;
}
