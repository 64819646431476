@import '../../../constants/scss/everything';

.yo-navbar {
  background-color: var(--background-accent);
  padding: .5rem 1.25rem;
  text-align: right;
}

.yo-navbar__links {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.yo-navbar__link {
  @include default-s;
  color: var(--text-white);
  margin: 0 1em;
  text-decoration: none;
  transition: opacity 80ms ease-in-out;

  &::first-letter {
    text-transform: uppercase;
  }

  &:active,
  &:hover,
  &--active {
    opacity: .9;
  }
}

.yo-navbar__link-disabled, 
.yo-navbar__link-disabled:active,
.yo-navbar__link-disabled:hover {
  @include default-s;
  cursor: default;
  color: var(--text-accent--light);
  filter: saturate(20%);
  margin: 0 1em;
  opacity: .2;

  &::first-letter {
    text-transform: uppercase;
  }
}

.yo-navbar__link-button {
  color: var(--text-accent--light);
  display: inline-block;
  height: 2.5rem;
  margin: 1rem 0 .5rem 2rem;
  padding: .75em 1.25rem;
  transition: opacity 80ms ease-in-out;

  &:active,
  &:hover,
  &--active {
    opacity: 1;
  }

  svg {
    fill: var(--background-accent--bright);
  }
}

.yo-navbar__save-button {
  background-color: var(--background-accent--light);

  &:hover {
    background-color: var(--background-accent--dark);
  }

  &--active {
    background-color: var(--background-accent);

    &:hover {
      background-color: var(--background-accent--dark);
    }
  }
}

@include for-tablet-up {
  .yo-navbar__link-button {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0 0 2rem;
  }
}

.confirm-dialog-buttons {
  margin-bottom: 1rem;
}
