@import '../../../constants/scss/everything';

// Parent element that has both the YDS input and review.
.yo-gap-option {
  display: inline-block;
  height: auto;
  margin: 0 $yds-spacing-4;
  white-space: nowrap;
  width: auto;
}

// Contains both the answer and possible hint.
.yo-gap-option__review {
  display: inline;
}

// Where the right answer will be displayed, if the user answer is wrong.
.yo-gap-option__answer {
  display: inline;
  @include default-m-bold;
  padding-left: $yds-spacing-8;
  white-space: normal;
}

.yo-gap-option__hint {
  @include default-s;
  color: var(--text-secondary--dark);
  padding-right: $yds-spacing-8;
  white-space: initial;
}

.yo-gap-option__input {
  display: inline-block;
  height: auto;
  width: auto;

  fieldset {
    display: inline;

    input {
      height: $yds-spacing-24;
      padding: $yds-spacing-8;
    }

    label {
      display: none;
    }
  }
}

.yo-gap-option__numbering {
  @include default-s;
  color: var(--text-secondary--dark);

  &::before {
    counter-increment: gap-counter;
    content: counter(gap-counter) ') ';
    padding-right: $yds-spacing-4;
  }
}
