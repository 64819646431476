.exam-search {
  margin-top: auto;
}

.yo-exam-search__input {
  margin: .5em 0 0;

  .yo-input__label > input {
    padding: .4em;
  }
}
