@import '../../../constants/scss/everything';

.yo-tunnus-login-link {
  text-decoration: underline;
  cursor: pointer;
}

.yo-tunnus-login-icon-path {
  fill: var(--text-primary);
}
