@import '../../../constants/scss/everything';

.yo-main {
  flex: 1 1 65%;
  padding: 1em;
  background-color: var(--background-primary);
}

.yo-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: var(--text-primary);
}

.yo-page__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}

@include for-desktop-up {
  .yo-main {
    min-height: 100%;
    overflow-y: auto;
  }

  .yo-page__content {
    flex-direction: row;
  }
}
