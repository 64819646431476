@import '../../../constants/scss/everything';

.yo-fragment__content {
  width: 100%;
  overflow-wrap: anywhere;
}

.yo-fragment__content h3 {
  @include default-xl-bold;
}

.yo-fragment__text {
  margin-bottom: $yds-spacing-8;
}

.yo-fragment__display-number {
  // Flexbox here is workaround to fix @yle-design-system/Tag component's height on firefox
  display: flex;
  margin-bottom: $yds-spacing-8;
}
