@import '../../../constants/scss/everything';

.yo-image {
  margin: 0;
  position: relative;
}

.yo-image__captions {
  @include default-s;
  text-align: left;
  word-break: break-word;
  margin-top: $yds-spacing-16;
  margin-bottom: $yds-spacing-32;

  &--center {
    text-align: center;
  }
}

.yo-image__caption-link {
  position: absolute !important;
  top: $yds-spacing-8;
  right: $yds-spacing-8;
}

.yo-image__caption-toggle {
  color: var(--text-secondary);
  cursor: pointer;
  @include default-s;
  font-style: italic;
  margin-left: .5em;
  outline: 0;
  user-select: none;
  word-break: break-word;

  &::after {
    content: ')';
  }

  &::before {
    content: '(';
  }

  &:active,
  &:hover {
    color: var(--text-secondary--dark);
  }
}

.yo-image__copyright {
  color: var(--text-secondary);
  display: block;
  @include default-s;
  margin-top: .5em;

  &:hover {
    color: var(--text-secondary--light);
  }
}

.yo-image__image {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  border-radius: $yds-border-radius-medium;
}
