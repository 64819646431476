@import '../../../constants/scss/everything';

.yo-dropdown {
  background: var(--background-secondary);
  border: 1px solid var(--border-primary--dark);
  border-radius: .125em;
  box-shadow: 0 0 .25rem 2px var(--shadow);
  display: flex;
  flex-flow: column;
  outline: 0;
  padding: 1em;
  position: fixed;
  right: 7rem;
  text-align: left;
  top: 6rem;
  transition: top 400ms cubic-bezier(.9, -.1, .1, 1.1);

  &--hidden {
    top: -14em;
  }
}

.yo-dropdown__input {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: .25em 0;
  user-select: none;
}

.yo-dropdown__input-field {
  background-color: var(--background-primary);
  border: 1px solid var(--border-primary);
  color: var(--text-secondary--dark);
  margin-right: .5em;
  min-width: 25em;
  padding: .25rem;
  transition: background 200ms ease-in-out;

  &--copied {
    background-color: var(--background-accent--highlight);
  }
}

.yo-dropdown__input-title {
  @include default-s;
  flex: 1 0 auto;
  margin-right: 1em;
  text-align: right;
}

.yo-dropdown__input-notice {
  @include default-s;
  align-items: center;
  background: var(--background-primary--dark);
  border-bottom: 1px solid var(--border-primary--light);
  cursor: default;
  display: flex;
  margin: -1rem -1rem 1rem;
  padding: 1rem 1rem .75rem;
  user-select: none;
}

.yo-dropdown__input-notice-text {
  padding: .25rem;
}
