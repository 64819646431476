@import '../../../constants/scss/everything';

.yo-series-container {
  margin: auto;
  max-width: 50em;
}

.yo-series-container__desc {
  @include default-s;
  background-color: var(--background-secondary);
  border: 1px solid var(--border-primary--light);
  color: var(--text-secondary);
  margin-bottom: 2em;
  padding: 1em;
}

.yo-series-container__create {
  text-align: center;
}

.yo-series-container__edit-order {
  margin: 1em;
  text-align: center;
}

.yo-series-container__icon-buttons {
  display: flex;
  justify-content: flex-end;
}
