.yo-icon {
  display: inline-flex;
  padding: .25rem;
  svg {
    fill: var(--fill-primary);
  }

  &--small {
    padding: .25rem;
  }
}
