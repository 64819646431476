@import '../../../constants/scss/everything';

.yo-exam-logo {
  align-items: center;
  cursor: default;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  user-select: none;
}

.yo-exam-logo__image {
  margin-right: .5em;

  & > .yo-icon {
    max-width: 5rem;
    svg {
      fill: var(--text-secondary);
    }
  }
}

.yo-exam-logo__text {
  color: var(--text-secondary);
}

.yo-exam-logo__text-row {
  display: block;
}

@include for-tablet-up {
  .yo-exam-logo {
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-bottom: 1em;
  }

  .yo-exam-logo__image {
    margin-right: 1em;

    & > .yo-icon {
      max-width: initial;
    }
  }
}
