@import '../../../constants/scss/everything';

@mixin child-margins {
  & > *:not(:last-child) {
    margin-bottom: $yds-spacing-8;
  }
}

.yo-combine-question {
  display: flex;
  flex-direction: column;

  @include child-margins;
}

.yo-combine-question__detach {
  align-self: center;
}

.yo-combine-question__correct-answers {
  margin: 0 $yds-spacing-16;
  @include child-margins;
}

.yo-combine-question__correct-answers-text {
  margin-top: $yds-spacing-16;
}
