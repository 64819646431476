@import '../../../constants/scss/everything';

.yo-disclaimer {
  @include default-xs;
  margin: $yds-spacing-8 0 $yds-spacing-16 0;

  & > *:not(:last-child) {
    margin-bottom: $yds-spacing-8;
  }
}

.yo-disclaimer__login {
  @include default-s-bold;
}
