@import '../../../constants/scss/everything';

.yo-accordion {
  margin: .5rem 0;
}

.yo-accordion__content {
  padding: 1em;
}

.yo-accordion__items {
  background-color: var(--background-primary);
  border: 1px solid var(--border-primary--dark);
  box-shadow: 0 1px 2px var(--shadow);
  margin: .75rem 0;
}

.yo-accordion__title {
  margin-bottom: .25rem;
}
