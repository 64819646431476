@import '../../../constants/scss/everything';

.yo-question-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 0;
  min-width: 0;

  &--centered {
    align-items: center;
  }

  & > .yo-exam-question {
    border: 0;
  }
}

.yo-question-list__paragraph {
  margin-bottom: 2em;
}

.yo-question-list__progressbar {
  margin-bottom: 2em;
  width: 100%;
}

.yo-question-list__question-item {
  border-bottom: 1px solid var(--border-accent--light);
  padding: 1rem;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.yo-question-list__exam-link {
  color: var(--text-accent);
  text-decoration: none;

  &:hover {
    color: var(--text-accent--dark);
    text-decoration: underline;
  }
}

.yo-question-list__order-number {
  @include default-m-bold;
}

.yo-question-header {
  @include default-2xl-bold;
  display: block;
  margin-bottom: .1em;
  margin-top: .67em;
}

.yo-question-header__info {
  color: var(--text-secondary);
  margin-left: .3rem;
}
