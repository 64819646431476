@import '../../../constants/scss/everything';

.yo-tabs__labels {
  background-color: var(--background-accent--secondary);
  border-bottom: 1px solid var(--border-primary);
  display: flex;
  flex-wrap: wrap;
  margin: -.5em -1em 0;
  padding: .75em 1em;
}

.yo-tabs__links {
  @include default-s;
  display: flex;
  flex-flow: row wrap;
}

.yo-tabs__link {
  align-items: center;
  color: var(--text-accent);
  cursor: pointer;
  display: flex;
  opacity: .67;
  outline: 0;
  padding: .5em;
  text-decoration: none;
  transition: opacity 80ms ease-in-out;

  &:last-child {
    margin-right: 0;
  }

  &:active,
  &:hover,
  &--active {
    color: var(--text-accent--dark);
    opacity: 1;
  }

  &--active {
    border: 1px solid var(--border-primary);
    border-radius: 2px;
  }
}

.yo-tabs__panel {
  margin-top: 1em;
}

.yo-tabs__title {
  text-transform: uppercase;
  width: 100%;
}

@include for-tablet-up {
  .yo-tabs__link {
    margin-right: .75em;
  }
}
