.yo-scored-vote-scoring {
  &__score-input-label {
    min-width: 4.5em;
    margin-right: .5em;
    margin-top: .45em;
  }
  &__score-input-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
