@import '../../../constants/scss/everything';

.yo-pagination {
  padding-left: 0;
}

.yo-pagination-page-link {
  @include default-s;
  color: var(--text-primary);
  margin: 1em .5em;
  outline: 0;
  padding: .5em;
}

.yo-pagination-page {
  display: inline-block;
  padding: .3em 0;

  &--active > .yo-pagination-page-link {
    background-color: var(--background-accent);
    color: var(--text-accent--light);
  }
}

@include for-desktop-up {
  .yo-pagination-page-link {
    cursor: pointer;
    user-select: none;
  }
}
