@import '../../../constants/scss/everything.scss';

.yo-option-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include default-m;
  background-color: var(--yds-color-background-interactive);
  border: $yds-border-md solid var(--yds-color-background-interactive);
  border-radius: $yds-border-radius-large;
  padding: 0 $yds-spacing-16;
  min-height: $yds-spacing-48;
  width: 100%;
  text-align: left;
  word-break: break-word;

  &--selected {
    border: $yds-border-md solid var(--yds-color-border);
  }

  &--wrong {
    border-color: var(--yds-color-feedback-error);
  }

  &--correct {
    border-color: var(--yds-color-feedback-success);
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      background-color: var(--yds-color-action-secondary-variant);
      border: $yds-border-md solid var(--yds-color-border);
    }
  }
}
