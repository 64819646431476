@import 'src/constants/scss/everything.scss';

.yo-app, #yo-tehtava-body, #yo-tehtava-embed {
  @include default-m;
  margin: 0;
  padding: 0;

  * {
    box-sizing: border-box;
  }
}
