@import '../../../constants/scss/everything';

.yo-exams {
  padding: 1em 0;
}

.yo-exams__row {
  @include default-l;
  border-bottom: 1px solid var(--border-primary--light);
  display: flex;
  flex-direction: column;
  padding: .8em 0;
}

.yo-exams__link-text {
  color: var(--text-accent);
  cursor: pointer;

  &:hover {
    color: var(--text-accent--dark);
  }
}

.yo-exams__header {
  display: none;
}

.yo-exams__item {
  @include default-s;
  display: flex;
  padding: .4em .5em;

  &::before {
    content: attr(data-column-title);
    font-weight: 600;
    width: 5em;
  }
}

@include for-desktop-up {
  .yo-exams__header {
    border-bottom: 1px solid var(--border-primary--light);
    display: flex;
    flex-direction: row;
    font-weight: 700;
    margin: 0 .15em;
    margin-bottom: .25em;
    padding: .1rem 0;
  }

  .yo-exams__row {
    flex-direction: row;
  }

  .yo-exams__item {
    @include default-m;
    &::before {
      display: none;
    }
  }

  .yo-exams__item--name {
    flex-basis: 400px;
  }

  .yo-exams__item--score {
    flex-basis: 200px;
  }

  .yo-exams__item--progress {
    flex-basis: 100px;
  }
}
