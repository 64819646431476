@import '../../../constants/scss/everything';

.yo-sidebar {
  background-color: var(--background-secondary);
  border-bottom: 1px solid var(--border-primary);
  padding: 1em;
}

@include for-desktop-up {
  .yo-sidebar {
    border-bottom: 0;
    border-right: 1px solid var(--border-primary);
    flex: 1 0 35%;
    overflow-y: auto;
    transition: flex-basis 150ms cubic-bezier(.9, -.2, .1, 1.2);

    &--expanded {
      flex-basis: 50%;
    }
  }
}
