@import '../../../constants/scss/everything';

@mixin child-margins {
  & > *:not(:last-child) {
    margin-bottom: $yds-spacing-8;
  }
}

.yo-order-question {
  @include child-margins;
}

.yo-order-question__selected {
  display: flex;
  flex-direction: row;

  @include for-mobile-only {
    flex-direction: column;
  }
}

.yo-order-question__options {
  flex: 1;
  @include child-margins;
}

.yo-order-question__options--selected {
  flex: 1;
  @include child-margins;

  @include for-mobile-only {
    &:nth-child(2) {
      margin-top: $yds-spacing-16;
    }
  }

  @include for-tablet-up {
    &:last-child {
      margin-left: $yds-spacing-12;
    }
  }
}

.yo-multiple-choice-question__option-points {
  margin-left: $yds-spacing-8;
  color: var(--yds-color-text-medium-emphasis);
}

.yo-order-question__option {
  &--wrapper {
    display: flex;
    align-items: center;
  }

  &--index {
    display: inline-block;
    @include default-m-bold;
    height: 100%;
    vertical-align: middle;
    margin-right: $yds-spacing-12;
  }
}
