@import '../../../constants/scss/everything';

.yo-settingsform {
  margin: 0 auto;
  margin-bottom: 12em; /* Quick fix for issue #1143 */
}

.yo-settingsform--loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@include for-desktop-up {
  .yo-settingsform {
    max-width: 22em;
  }
}
