@import '../../../constants/scss/everything';

.yo-separator {
  display: block;
  height: .1em;
  border: 0;
  border-top: 1px solid var(--border-primary);
  margin: 1em 0;
  padding: 0;
}
