@import '../../constants/scss/everything';

// Spacing between each question.
//
// Style is applied to root/top level question too. To get rid of it, you can use:
//   &:first-of-type {
//     margin-bottom: 0;
//   }
.yo-exam-question:not(:last-child) {
  margin-bottom: $yds-spacing-32;
}

.yo-exam-question__exam-link {
  color: var(--text-accent);
  cursor: pointer;

  &:hover {
    color: var(--text-accent--dark);
    text-decoration: underline;
  }
}

.yo-exam-question__exam-name {
  color: var(--text-secondary--light);
  margin: 2em 0;
}
