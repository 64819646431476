@import '../../../constants/scss/everything';

.yo-areena-player {
  min-width: 15em;
  max-height: 100vh;
  max-width: 100%;
  width: 100%;
}

// Prevent Kaltura player preview image
// from covering other HTML elements
div.yo-areena-player {
  overflow: hidden;
}

.yo-simple-media-player {
  position: relative;

  video {
    max-height: 100vh;
    max-width: 100%;
    width: 100%;
  }

  .yo-icon-button {
    background-color: var(--shadow--dark);
    border: 0;
    border-radius: 100%;
    cursor: pointer;
    padding: .5em;
    position: absolute;
    right: 0;
    margin-right: .25em;
    margin-top: .25em;
    z-index: 1;
  }
}

.yo-simple-media-player__error {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.yo-simple-media-player__error-div {
  position: relative;
  left: -50%;
  display: flex;
  align-items: center;
  z-index: 1;

  .yo-icon svg {
    fill: var(--text-primary);
  }
}

.yo-simple-media-player__error-div-span {
  @include default-l;
  margin-left: .25em;
}
