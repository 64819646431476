@import '@yleisradio/yds-core/lib/tokens/build/scss/_typography.scss';

$fallback-fonts: "Open Sans", sans-serif;

@mixin default-xs {
  font-family: $yds-typography-default-xs-font-family, $fallback-fonts;
  font-size: $yds-typography-default-xs-font-size;
  font-style: $yds-typography-default-xs-font-style;
  font-weight: $yds-typography-default-xs-font-weight;
  line-height: $yds-typography-default-xs-line-height;
  text-decoration: $yds-typography-default-xs-text-decoration;
}

@mixin default-s {
  font-family: $yds-typography-default-s-font-family, $fallback-fonts;
  font-size: $yds-typography-default-s-font-size;
  font-style: $yds-typography-default-s-font-style;
  font-weight: $yds-typography-default-s-font-weight;
  line-height: $yds-typography-default-s-line-height;
  text-decoration: $yds-typography-default-s-text-decoration;
}

@mixin default-m {
  font-family: $yds-typography-default-m-font-family, $fallback-fonts;
  font-size: $yds-typography-default-m-font-size;
  font-style: $yds-typography-default-m-font-style;
  font-weight: $yds-typography-default-m-font-weight;
  line-height: $yds-typography-default-m-line-height;
  text-decoration: $yds-typography-default-m-text-decoration;
}

@mixin default-l {
  font-family: $yds-typography-default-l-font-family, $fallback-fonts;
  font-size: $yds-typography-default-l-font-size;
  font-style: $yds-typography-default-l-font-style;
  font-weight: $yds-typography-default-l-font-weight;
  line-height: $yds-typography-default-l-line-height;
  text-decoration: $yds-typography-default-l-text-decoration;
}

@mixin default-xs-bold {
  font-family: $yds-typography-default-xs-bold-font-family, $fallback-fonts;
  font-size: $yds-typography-default-xs-bold-font-size;
  font-style: $yds-typography-default-xs-bold-font-style;
  font-weight: $yds-typography-default-xs-bold-font-weight;
  line-height: $yds-typography-default-xs-bold-line-height;
  text-decoration: $yds-typography-default-xs-bold-text-decoration;
}

@mixin default-s-bold {
  font-family: $yds-typography-default-s-bold-font-family, $fallback-fonts;
  font-size: $yds-typography-default-s-bold-font-size;
  font-style: $yds-typography-default-s-bold-font-style;
  font-weight: $yds-typography-default-s-bold-font-weight;
  line-height: $yds-typography-default-s-bold-line-height;
  text-decoration: $yds-typography-default-s-bold-text-decoration;
}

@mixin default-m-bold {
  font-family: $yds-typography-default-m-bold-font-family, $fallback-fonts;
  font-size: $yds-typography-default-m-bold-font-size;
  font-style: $yds-typography-default-m-bold-font-style;
  font-weight: $yds-typography-default-m-bold-font-weight;
  line-height: $yds-typography-default-m-bold-line-height;
  text-decoration: $yds-typography-default-m-bold-text-decoration;
  text-transform: $yds-typography-default-m-bold-text-case;
}

@mixin default-xl-bold {
  font-family: $yds-typography-default-xl-bold-font-family, $fallback-fonts;
  font-size: $yds-typography-default-xl-bold-font-size;
  font-style: $yds-typography-default-xl-bold-font-style;
  font-weight: $yds-typography-default-xl-bold-font-weight;
  line-height: $yds-typography-default-xl-bold-line-height;
  text-decoration: $yds-typography-default-xl-bold-text-decoration;
}

@mixin default-xl-heavy {
  font-size: $yds-typography-default-xl-heavy-font-size;
  text-decoration: $yds-typography-default-xl-heavy-text-decoration;
  font-family: $yds-typography-default-xl-heavy-font-family, $fallback-fonts;
  font-weight: $yds-typography-default-xl-heavy-font-weight;
  font-style: $yds-typography-default-xl-heavy-font-style;
  letter-spacing: $yds-typography-default-xl-heavy-letter-spacing;
  line-height: $yds-typography-default-xl-heavy-line-height;
}

@mixin default-2xl-bold {
  font-family: $yds-typography-default-2xl-bold-font-family, $fallback-fonts;
  font-size: $yds-typography-default-2xl-bold-font-size;
  font-style: $yds-typography-default-2xl-bold-font-style;
  font-weight: $yds-typography-default-2xl-bold-font-weight;
  line-height: $yds-typography-default-2xl-bold-line-height;
  text-decoration: $yds-typography-default-2xl-bold-text-decoration;
}

@mixin default-2xl-heavy {
  font-size: $yds-typography-yle-next-2xl-heavy-font-size;
  text-decoration: $yds-typography-yle-next-2xl-heavy-text-decoration;
  font-family: $yds-typography-yle-next-2xl-heavy-font-family, $fallback-fonts;
  font-weight: $yds-typography-yle-next-2xl-heavy-font-weight;
  font-style: $yds-typography-yle-next-2xl-heavy-font-style;
  font-stretch: $yds-typography-yle-next-2xl-heavy-font-stretch;
  letter-spacing: $yds-typography-yle-next-2xl-heavy-letter-spacing;
  line-height: $yds-typography-yle-next-2xl-heavy-line-height;
}

@mixin default-4xl-heavy {
  font-size: $yds-typography-default-4xl-heavy-font-size;
  text-decoration: $yds-typography-default-4xl-heavy-text-decoration;
  font-family: $yds-typography-default-4xl-heavy-font-family, $fallback-fonts;
  font-weight: $yds-typography-default-4xl-heavy-font-weight;
  font-style: $yds-typography-default-4xl-heavy-font-style;
  font-stretch: $yds-typography-default-4xl-heavy-font-stretch;
  letter-spacing: $yds-typography-default-4xl-heavy-letter-spacing;
  line-height: $yds-typography-default-4xl-heavy-line-height;
}
