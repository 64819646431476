@import '../../../constants/scss/everything';

.yo-toaster {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
