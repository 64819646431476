@import '../../../constants/scss/everything';

.yo-series-info {
  margin: 2.5em auto;
  max-width: 60em;

  .yo-accordion-item__header {
    border: 1px solid var(--border-accent);
  }

  .yo-accordion-item__title {
    color: var(--text-accent--dark);
  }
}

.yo-series-info__desc {
  @include default-s;
  margin-bottom: 1em;
}

.yo-series-info__content {
  padding: 0 .5em;
}

.yo-series-info__wrapper {
  border: 1px solid var(--border-primary);
  padding: 1em;
}

.yo-series-info__promote {
  color: var(--text-secondary);
  font-style: italic;
  margin-top: 1em;

  &-link {
    background: none;
    border: 0;
    color: var(--background-accent--yle);
    cursor: pointer;
    font-style: italic;
    margin: 0 0 0 .3em;
    padding: 0;

    &:active,
    &:hover,
    &--active {
      background-color: var(--background-primary);
    }
  }
}
