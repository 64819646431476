@import '../../../constants/scss/everything';

.yo-link {
  color: var(--text-primary);
  text-decoration: underline;

  &:active,
  &:hover {
    color: var(--text-accent--dark);
  }
}
