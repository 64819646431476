@import '../../../constants/scss/everything';

.yo-vote-question__vote-ended {
  margin-bottom: 1em;
}

.yo-vote-question__show_results {
  @include default-s;
  color: var(--text-secondary);
  margin-top: 1em;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.yo-vote-results__total-count {
  @include default-s;
  color: var(--text-secondary);
  margin-top: 1em;
}

.yo-vote-results__updated-at {
  @include default-s;
  color: var(--text-secondary);
}

.yo-vote-question__option {
  display: flex;
  align-items: center;
}

.yo-vote-question__free-text-input {
  margin: 0 0 0 $yds-spacing-8;
  width: 15em;
}

.free-text-input-container {
  margin: 0 $yds-spacing-16 $yds-spacing-12;
}
