@import '../../../constants/scss/everything';

.yo-series-exam-order {
  &__description {
    @include default-s;
    margin: 1em 0;
  }

  &__row {
    align-items: center;
    border: 1px dashed var(--border-primary--dark);
    border-radius: 4px;
    display: flex;
    margin-bottom: .5em;
    padding: .5em;
    transition: background-color .1s;

    &:hover {
      background-color: var(--background-accent--secondary);
    }
  }

  &__order {
    margin-right: 1em;
    width: 3em;
  }

  &__name {
    flex: 1;
  }

  &__published {
    flex: 1;
  }

  &__header {
    border: 0;
    font-weight: bold;

    &:hover {
      background-color: transparent;
    }
  }

  &__dragging {
    background-color: var(--background-accent--secondary);
  }
}
