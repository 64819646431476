@import '../../../constants/scss/everything.scss';

.yo-spaced-group {
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: $yds-spacing-8;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--center {
    align-items: center;
  }

  &--top-spacing {
    margin-top: $yds-spacing-8;
  }

  &--bottom-spacing {
    margin-bottom: $yds-spacing-8;
  }
}
