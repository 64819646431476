@import '../../../constants/scss/everything';

.yo-sharing-review__wrapper {
  margin-top: $yds-spacing-16;
}

.yo-sharing-review__icons {
  display: flex;
  justify-content: space-between;
  width: 150px;
}
