@import '../../../constants/scss/everything';

.yo-timer-start__button {
  text-align: center;
}

.yo-timer-start__invalid-duration {
  @include default-s;
  color: var(--text-accent--error);
  margin-top: .8em;
}
