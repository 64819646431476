@import '../../../constants/scss/everything';

.yo-poll-results__label {
  &-selected {
    font-weight: bold;
  }
}

.yo-poll-results__option {
  margin-bottom: 1px;
}

.yo-poll-results__image {
  margin-bottom: .25em;
  max-width: 60%;

  &.yo-poll-results__label-selected {
    border: 2px dashed var(--border-accent);
    padding: .2em;
  }
}

.yo-poll-results__total-count {
  @include default-s;
  color: var(--text-secondary);
}

@include for-tablet-up {
  .yo-poll-results__image {
    max-width: 30%;
  }
}
