@import '../../../constants/scss/everything';

.yo-accordion-item {
  &--highlighted {
    background-color: var(--background-accent--highlight);
  }

  &--invalid {
    border: 1px solid var(--text-accent--error);
  }

  &__toggle {
    svg {
      fill: var(--text-secondary--light);
    }
  }
}

.yo-accordion-item__description {
  font-weight: 400;
  margin-left: .85em;
}

.yo-accordion-item__title {
  @include default-s;
  color: var(--text-secondary--dark);
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.yo-accordion-item__header {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: .5rem 1.25rem;

  &--expanded {
    border-bottom: 1px dashed var(--border-primary--dark);

    .yo-accordion-item__title {
      font-weight: 400;
    }
  }

  &:focus,
  &:active,
  &:hover {
    background-color: var(--background-accent--highlight)
  }
}

.yo-accordion-item__prefix {
  align-items: center;
  display: flex;
  margin-right: .75rem;
  max-width: 100%;
}

.yo-accordion-item__suffix {
  margin-left: .75rem;
}
