@import '../../../constants/scss/everything';

.yo-exam-logs__log {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 80%;

  & > .yo-exam-logs__log-col {
    flex: 1;
    min-height: 2em;
    padding: .5em 0;
    text-align: center;

    &--message {
      @include default-s;
      flex: 2;
    }
  }

  &:nth-child(2n+1) {
    background: var(--background-secondary);
  }
}

.yo-exam-logs__logs-header {
  border-bottom: 2px solid var(--border-primary--light);
  font-weight: bold;
}

.yo-exam-logs__response-icon {
  svg {
    fill: var(--fill-success);
  }
  &--incorrect {
    svg {
      fill: var(--fill-error);
    }
  }
}

.yo-exam-logs__spinner-wrapper {
  display: flex;
  align-content: space-between;
  justify-content: center;
}
