@import "../../../constants/scss/everything";

.yo-exam-root-authors {
    padding-top: .5rem;
    text-align: right;
}

.yo-exam-root-authors__author-link {
    @include default-s;
    color: var(--text-primary);
    font-style: italic;
    padding-right: .25rem;
    text-decoration: none;

    &::after {
        content: ', ';
        display: inline-block;
    }

    &:last-of-type {
        &::after {
            display: none;
        }
    }
}

.yo-exam-root__editor-display-settings-container {
    margin-bottom: $yds-spacing-16;
}
