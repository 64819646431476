@import '../../../constants/scss/everything';

.yo-tags {
  margin: 1em 0;
}

.yo-tags__tag {
  @include default-xs-bold;
  align-items: center;
  background-color: var(--background-accent--light);
  border-radius: .25rem;
  color: var(--text-accent--light);
  cursor: default;
  display: inline-flex;
  justify-content: center;
  margin-bottom: .5em;
  margin-right: .5em;
  padding: .125rem .25rem;

  &:active,
  &:hover {
    background: var(--background-accent);
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &--read-only {
    &:active,
    &:hover {
      background-color: var(--background-accent--light);
      cursor: default;
    }
  }

  &--carousel-clickable {
    &:hover {
      color: var(--text-accent--dark);
      cursor: pointer;
    }
  }

  &--secondary {
    background: transparent;
    border-radius: 0;
    color: var(--text-accent);
    font-style: italic;
    margin: 0;
    padding: 0;

    &:active,
    &:hover {
      background: transparent;
    }
  }
}

.yo-tags__tag-close {
  display: inline-block;
  margin-left: .5em;
  outline: 0;

  &:active,
  &:hover {
    cursor: pointer;

    & .yo-icon-svg-path {
      fill: var(--fill-secondary);
    }
  }
}

.yo-tags__tag-link {
  color: var(--text-accent--light);
  text-decoration: none;
}

.yo-tags__tag-title {
  // Add same amount of padding as .yo-tags__tag-close gets from Icon!
  padding-left: .25rem;
  padding-right: .25rem;
}
