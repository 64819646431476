@import '../../../constants/scss/everything';

.yo-publishform {
  margin: 0 auto;
}

@include for-desktop-up {
  .yo-publishform {
    max-width: 22em;
  }
}
