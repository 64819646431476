@import '../../../constants/scss/everything';

.yo-loader {
  align-items: center;
  display: flex;
}

.yo-loader__animation {
  animation-duration: 2s;
  user-select: none;
  z-index: 9999;

  &--spinner {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: spinningProgressBar;
    animation-timing-function: linear;
  }

  &--horizontal {
    animation-fill-mode: both;
    animation-name: horizontalProgressBar;
    animation-timing-function: ease;
    background-color: var(--background-accent--light);
    height: .125rem;
    left: 0;
    position: relative;
    top: 0;
    width: 0%;
  }
}

.yo-loader__dots {
  display: flex;
  margin: 6rem auto;
}

.yo-loader__dot {
  animation: dots 2s infinite ease-in-out both;
  background-color: var(--background-accent--light);
  border-radius: 100%;
  height: 1rem;
  margin: 0 .25rem;
  width: 1rem;

  &--dot2 {
    animation-delay: 200ms;
  }

  &--dot3 {
    animation-delay: 400ms;
  }
}

.yo-loader__scaleout {
  animation: scaleOut 2s infinite ease-in-out;
  border: .25em solid var(--background-accent--light);
  border-radius: 100%;
  height: 3rem;
  margin: 6rem;
  width: 3rem;
}

@keyframes dots {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes horizontalProgressBar {
  0% {
    width: 0%;
  }

  25% {
    width: 22%;
  }

  50% {
    width: 55%;
  }

  75% {
    width: 83%;
  }

  100% {
    width: 100%;
  }
}

@keyframes spinningProgressBar {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(0);
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
