@import '../../../constants/scss/everything';

.yo-series-list {
  margin: auto;
  max-width: 60em;
}

.yo-series-list__header {
  border-bottom: 1px solid var(--border-primary--light);
  display: flex;
  font-weight: 700;
  justify-content: space-between;
}

.yo-series-list__row {
  border-bottom: 1px solid var(--border-primary--light);
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
}

.yo-series-list__item {
  &--updated {
    margin: 0 1em;
    width: 100%;
  }

  &--action {
    margin: 0 1em;
    width: 50%;
  }

  &--name {
    width: 100%;

    & a {
      color: var(--text-primary);
      text-decoration: none;

      &:active,
      &:hover {
        color: var(--text-accent);
      }
    }
  }
}

.yo-series-container__create {
  margin-bottom: 2em;
}

@include for-tablet-up {
  .yo-series-list__item {
    &--updated {
      width: 75%;
    }

    &--action {
      margin: 0 1em;
      width: 20%;
    }

    &--name {
      margin-left: 1em;
      width: 100%;
    }
  }
}
