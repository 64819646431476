@import '../../../constants/scss/everything';

.yo-toast {
  @include default-s;
  align-items: center;
  background-color: var(--background-accent--dark);
  border: 1px solid var(--border-primary--xdark);
  border-radius: 0 0 .25rem .25rem;
  border-top: 0;
  color: var(--text-accent--light);
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  opacity: 1;
  padding: .75em 1.5em .5em;
  pointer-events: auto;
  position: fixed;
  top: 0;
  transition: opacity 250ms, top 250ms cubic-bezier(.9, -.33, .1, 1.33);
}

.yo-toast__icon {
  margin-left: 1em;

  &--progress {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: rotate;
    animation-timing-function: linear;
  }
}

.yo-toast__message {
  margin: 0 .25rem;
  padding: .25rem 0;
}

.yo-toast-enter {
  opacity: .01;
  top: -100px;
}

.yo-toast-enter-active {
  opacity: 1;
  top: 0;
}

.yo-toast-exit {
  opacity: 1;
  top: 0;
}

.yo-toast-exit-active {
  opacity: .01;
  top: -100px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
