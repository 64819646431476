@import '../../../constants/scss/everything';

.yo-dialog {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  outline: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  // In the Dialog component, make button elements have their original outline
  & button {
    &:not(:active) {
      outline-width: initial;
    }
  }
}

.yo-dialog__background {
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.yo-dialog__label {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.yo-dialog__input {
  @include default-s;
  border: 1px solid var(--border-primary--dark);
  background-color: var(--background-primary);
  color: var(--text-primary);
  border-radius: 2px;
  box-shadow: inset 1px 1px .25rem var(--shadow);
  display: block;
  margin: .25rem 0;
  max-width: 100%;
  padding: .25rem;
  width: 100%;

  &--wrapper {
    border: 0;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    padding: 0;

    & .yo-button {
      margin: .25rem 0 .25rem .75rem;
    }
  }
}

.yo-dialog__checkbox {
  margin: .25rem 0;
  padding: .25rem;
  width: 100%;
}

.yo-dialog__input-column {
  display: flex;
  flex: 1 1 80%;
  flex-flow: column wrap;
  margin: .25rem 0;
}

.yo-dialog__item {
  margin-bottom: .75rem;
}

.yo-dialog__popup {
  position: absolute;
  background: var(--background-primary);
  border-radius: 3px;
  box-shadow: 0 0 3.25rem 0 var(--shadow--dark);
  width: 100%;
  z-index: 20;
}

.yo-dialog__popup-buttons {
  display: flex;
  justify-content: flex-end;
  padding: .5rem 1rem 1rem;
}

.yo-dialog__popup-content {
  padding: 1rem;
}

.yo-dialog__popup-title {
  background-color: var(--background-accent--light);
  border-radius: 2px 2px 0 0;
  color: var(--text-accent--light);
  font-weight: 500;
  padding: .5rem 1rem;
}

.yo-dialog__description {
  @include default-s;
  color: var(--text-secondary);
  text-align: center;
}

@include for-tablet-up {
  .yo-dialog__select {
    margin-left: 1em;
  }

  .yo-dialog__input {
    flex: 1 1 80%;
  }

  .yo-dialog__label {
    align-items: center;
    flex-direction: row;
  }

  .yo-dialog__title {
    flex: 1 0 20%;
    margin-right: 1rem;
    text-align: right;
  }

  .yo-dialog__popup {
    min-width: 32rem;
    width: auto;
    max-width: 48rem;
  }
}
