@import '../../../constants/scss/everything';

.yo-select {
  margin-bottom: .75rem;
}

.yo-select__description {
  @include default-s;
}

.yo-select__label {
  margin-right: .25rem;
}

.yo-select__select {
  background-color: var(--background-primary);
  color: var(--text-primary);
  border: 1px solid var(--border-primary);
  border-radius: 2px;
  display: block;
  height: 2em;
  margin: .25rem 0;
  max-width: 100%;
  padding: 0 .75rem;

  &--inline {
    display: inline;
  }
}
