@import '../../../constants/scss/everything';

.yo-progressbar {
  background: var(--yds-color-border-separator);
  border-radius: 2em;
  overflow: hidden;
  width: 100%;
}

.yo-progressbar__progress {
  background-color: var(--yds-color-player-progress-indicator);
  border-radius: 2em;
  display: block;
  height: 100%;
  transition: width .2s linear;
}

.yo-progressbar__progress-text {
  @include default-s;
  display: block;

  &--grey {
    color: var(--text-secondary);
  }
}
