@import '~@yleisradio/yds-core/lib/tokens/build/scss/_theme-light.scss';

.yo-app,
.yo-tehtava {
  // BACKGROUNDS
  // For main elements, e.g. the widget and the sidebar
  --background-primary: rgb(255, 255, 255);
  --background-primary--dark: rgba(160, 160, 160, .1);
  --background-primary--xdark: rgb(64, 64, 64);
  --background-secondary: rgb(248, 248, 248);
  --background-secondary--light: rgb(253, 253, 253);
  // For accented elements, e.g. the header and navbar, buttons, and so on
  --background-disabled: rgb(241, 241, 241);
  --background-accent: rgb(0, 120, 130);
  --background-accent--bright: rgb(255, 0, 90);
  --background-accent--dark: rgb(0, 95, 105);
  --background-accent--error: rgba(255, 232, 230, .2);
  --background-accent--highlight: rgba(255, 250, 238, .5);
  --background-accent--light: rgb(0, 120, 130);
  --background-accent--notification: rgba(0, 120, 130, .05);
  --background-accent--score: rgba(0, 120, 130, .08);
  --background-accent--secondary: rgb(235, 240, 240);
  --background-accent--xbright: rgb(240, 115, 15);
  --background-accent--yle: rgb(0, 181, 200);
  --background-input--correct: rgba(201, 234, 116, .6);
  --background-input--incorrect: rgba(255, 131, 131, .4);

  // TEXT
  // For main elements
  --text-primary: rgb(16, 16, 16);
  --text-primary--light: rgb(32, 32, 32);
  --text-secondary: rgb(118, 118, 118);
  --text-secondary--light: rgb(192, 192, 192);
  --text-secondary--dark: rgb(96, 96, 96);
  --text-white: rgb(255, 255, 255);
  // For accented elements
  --text-accent: rgb(0, 120, 130);
  --text-accent--dark: rgb(0, 95, 105);
  --text-accent--error: rgb(204, 0, 17);
  --text-accent--light: rgba(255, 255, 255, .95);

  // BORDERS
  // For main elements
  --border-primary: rgba(128, 128, 128, .2);
  --border-primary--dark: rgba(96, 96, 96, .2);
  --border-primary--light: rgba(128, 128, 128, .1);
  --border-primary--xdark: rgba(32, 32, 32, .2);
  --border-secondary: rgb(255, 255, 255);
  // For accented elements
  --border-accent: rgba(0, 95, 105, .5);
  --border-accent--light: rgb(238, 238, 238);

  // ICONS
  --fill-primary: rgb(0, 120, 130);
  --fill-secondary: rgb(255, 255, 255);
  --fill-success: rgb(120, 185, 40);
  --fill-error: rgb(255, 0, 90);

  // SHADOWS
  --shadow: rgba(128, 128, 128, .1);
  --shadow--dark: rgba(0, 0, 0, .3);

  // YDS COLORS
  --yds-color-action-secondary-variant: #{$yds-color-action-secondary-variant};
  --yds-color-background-interactive: #{$yds-color-background-interactive};
  --yds-color-border: #{$yds-color-border};
  --yds-color-border-separator: #{$yds-color-border-separator};
  --yds-color-feedback-error: #{$yds-color-feedback-error};
  --yds-color-feedback-success: #{$yds-color-feedback-success};
  --yds-color-feedback-highlight: #{$yds-color-feedback-highlight};
  --yds-color-text-default: #{$yds-color-text-default};
  --yds-color-text-medium-emphasis: #{$yds-color-text-medium-emphasis};
  --yds-color-background-control-disabled: #{$yds-color-background-control-disabled};
  --yds-color-player-progress-indicator: #{$yds-color-player-progress-indicator};
  --yds-color-text-dark: #{$yds-color-text-dark};
}
